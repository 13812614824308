import { Card, Box, Zoom, CardContent, Typography, CardActions, Button } from '@mui/material';
import React from 'react';
import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';

import { ReactComponent as ComingSoonIcon } from '../../../../../assets/icons/coming-soon.svg';
import { ReactComponent as WarningIcon } from '../../../../../assets/icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../../../../assets/icons/ok.svg';
import { HtmlTooltip } from '../../../../../components/htmlToolTip/HtmlToolTip';

export interface IConnectorCardProps {
  classes: any;
  cardName: string;
  cardIcon: string;
  onCardClick: any;
  onConnectClick: any;
  onClickSettings?: () => void;

  isConnected: boolean;
  isConnectorComplete: boolean;
  disabled?: boolean;
  comingSoon?: boolean;
  variant?: 'default' | 'google' | 'facebook';
}

export const ConnectorCard = (props: IConnectorCardProps) => {
  const renderConnectButton = () => {
    if (props.variant === 'google') {
      return <GoogleLoginButton onClick={props.onConnectClick} />;
    }

    if (props.variant === 'facebook') {
      return <FacebookLoginButton onClick={props.onConnectClick} />;
    }

    return (
      <Button
        size="small"
        className={`${props.disabled ? props.classes.Disabled : props.classes.ConnectButton}`}
        onClick={props.onConnectClick}
      >
        Connect
      </Button>
    );
  };

  return (
    <Card
      className={`${props.classes.Connector}`}
      onClick={props.isConnected && !props.isConnectorComplete ? props.onCardClick : undefined}
      sx={{
        height: '100%',
        cursor: props.isConnected && !props.isConnectorComplete ? 'pointer' : 'auto',
      }}
    >
      <Box component="span" className={`${props.classes.checkStatus}`}>
        {props.isConnected && !props.isConnectorComplete && (
          <HtmlTooltip
            title={
              <Box sx={{ textAlign: 'center' }}>
                More Configuration is needed for this connector, click the tile to configure
              </Box>
            }
            arrow
            followCursor
            TransitionComponent={Zoom}
            placement="top"
          >
            <WarningIcon />
          </HtmlTooltip>
        )}
      </Box>


      <Box component="span" className={`${props.classes.checkStatus}`}>
        {props.isConnected && (
          <HtmlTooltip
            title={<Box sx={{ textAlign: 'center' }}>Configured successfully, you may proceed to next step.</Box>}
            arrow
            followCursor
            TransitionComponent={Zoom}
            placement="top"
          >
            <SuccessIcon title="All good! Good to go." />
          </HtmlTooltip>
        )}
      </Box>

      <Box component="span" className={`${props.classes.checkStatus}`}>
        {props.isConnectorComplete && (
          <HtmlTooltip
            title={<Box sx={{ textAlign: 'center' }}>Configured successfully, you may proceed to next step.</Box>}
            arrow
            followCursor
            TransitionComponent={Zoom}
            placement="top"
          >
            <SuccessIcon title="All good! Good to go." />
          </HtmlTooltip>
        )}
      </Box>
      <CardContent>
        <Typography variant="h6" align="center">
          <img src={props.cardIcon} alt={props.cardName} />
        </Typography>
        <Typography variant="body2" align="center">
          {props.cardName}
        </Typography>
        {props.comingSoon && (
          <div
            style={{
              position: 'absolute',
              top: '-4px',
              right: '0',
              width: '80px',
              height: '80px',
            }}
          >
            <ComingSoonIcon />
          </div>
        )}
      </CardContent>
      <CardActions>
        {props.isConnected || props.isConnectorComplete ? (
          <Button size="small" disabled className={`${props.classes.Disabled}`}>
            Connected
          </Button>
        ) : (
          renderConnectButton()
        )}
      </CardActions>
    </Card>


// {connected ? (
//   <>
//     <IconButton
//       color="primary"
//       sx={{ position: 'absolute', top: '8px', right: '8px', width: '10px', height: '10px' }}
//       onClick={onClickSettings}
//     >
//       <SettingsIcon />
//     </IconButton>
//     <Button fullWidth size="small" id={compId + 'Recon'} onClick={onClick}>
//       Reconnect
//     </Button>
//   </>
// ) : (
//   renderConnectButton()
// )}
  );
};
