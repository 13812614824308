import { baseApi } from 'api/api';
import { Response } from '../../types/api/api';
import { CouponRequest, StripeCreateRequest, StripeStatusRequest } from 'types/stripe/stripe';

const createInitialStripeSetup = (data: StripeCreateRequest) =>
  baseApi.post<Response<string>>('Stripe/CreateInitialStripeSetup', data);

const getStatus = (data: StripeStatusRequest) => baseApi.post<Response<string>>('/Stripe/GetPaymentStatus', data);

const applyCoupon = (data: CouponRequest) => baseApi.post<Response<string>>('/Stripe/CreateCoupon', data);

const verifyCoupon = (code: string) => baseApi.get<Response<string>>(`Stripe/VerifyCoupon?couponCode=${code}`);

export const stripeApi = {
  createInitialStripeSetup,
  getStatus,
  applyCoupon,
  verifyCoupon,
};
