import { companyApi } from 'api/company';
import { AppDispatch } from 'store/store';

import { companyonboardingSlice } from './slice';

export const getAllBrandInfo = (brandId: string, onFailure: () => void) => async (dispatch: AppDispatch) => {
  dispatch(companyonboardingSlice.actions.processGetBrandData());
  try {
    const { data } = await companyApi.getAllBrandInfo(brandId);
    dispatch(companyonboardingSlice.actions.getBrandDataSuccess(data.result));
  } catch (error) {
    dispatch(companyonboardingSlice.actions.getBrandDataFailure());

    //onFailure();
  }
};
