import { CompanyState } from './types';

const plans = (state: CompanyState) => state.company.plans;

const plansLoading = (state: CompanyState) => state.company.plansLoading;

const companyRegisterLoading = (state: CompanyState) => state.company.companyRegisterLoading;

export const companySelectors = {
  plans,
  plansLoading,
  companyRegisterLoading,
};
