export const INDUSTRIES = [
  {
    name: 'Apparel',
    id: '320c884f-3d4d-476b-9689-887790071c3a',
  },
  {
    name: 'Cosmetics/Beauty',
    id: '7db7122f-2303-45c7-a876-93b9e5935548',
  },
  {
    name: 'Ecommerce',
    id: '5ee5e303-6dd0-4129-b5c8-9d60bdead49c',
  },
  {
    name: 'Education',
    id: '3098aac3-3945-405e-ac2c-648b6ac254b3',
  },
  {
    name: 'Services',
    id: '435db1da-29c9-40ba-9eb8-c821964aaea5',
  },
  {
    name: 'Technology',
    id: 'cab739cc-d908-4e9b-bb06-6536c4afafe0',
  }, 
  {
    name: 'Other',
    id: 'eca9c6a7-c12f-4c33-aec6-ad46d115da30',
  },  
];
