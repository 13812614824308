import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  CardElement,
  useStripe,
  useElements,
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import { useToast } from 'hooks/use-toast';
import './stripe.css'; // Import the CSS file
import { Stage } from 'types/account/account';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { accountSelectors } from 'store/entities/account/selectors';
import { updateRegistrationStage } from 'store/entities/registration-stage/actions';
import { Box, CircularProgress, Grid } from '@mui/material';
import { createInitialStripeSetup, verifyCoupon } from 'store/entities/stripe/action';
import { Plan } from 'types/company/company';

const Checkout = ({ selectedPlanId, clientId, plan }: { selectedPlanId: string; clientId: string; plan: Plan }) => {
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const elements = useElements();
  const { errorToast, successToast } = useToast();
  const [cardholderName, setCardholder] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [applyClicked, setApplyClick] = useState(false);

  const [subtotal, setSubtotal] = useState(plan.price);
  const [duration, setDuration] = useState('');
  const [durationPeriod, setDurationPeriod] = useState('');
  const [discount, setDiscount] = useState('');
  const [totalValue, setTotalValue] = useState(+plan.price);

  const [payBtnClicked, setpayBtnClicked] = useState(false);

  const userId = useAppSelector(accountSelectors.userId);
  const email = useAppSelector(accountSelectors.email);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [couponInputHeight, setCouponInputHeight] = useState(0);

  useEffect(() => {
    const couponInput = document.getElementById('coupon-input');
    if (couponInput) {
      setCouponInputHeight(couponInput.offsetHeight);
    }
  }, []);

  const onChangeStage = (stage: Stage) => {
    dispatch(updateRegistrationStage({ stage, userId: userId as string }, errorToast));
  };

  const togglePayBtn = (e:string, show:boolean = false) =>{
    if(!show)
    {
      setpayBtnClicked(true);
      document.getElementById(e).style.display = 'none';
    }
    else{
      setpayBtnClicked(false);
      document.getElementById(e).style.display = 'block';
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Update the state with the new input value
    setCardholder(event.target.value);
  };

  const applyCoupon = async () => {
    if (couponCode != null && couponCode != '') {
      try {
        setApplyClick(true);
        const coupon = await dispatch(verifyCoupon(couponCode));
        if (coupon) {
          setDiscount('');
          setCouponCode('');
          setTotalValue(+plan?.price);

          const result: any = coupon.result;
          if (result?.valid) {
            successToast('Successfully applied');
            const amountOff = result.couponDetails?.amount_off != null ? result.couponDetails?.amount_off / 100 : null;
            //amount of is the exact amount to deduct from value whereas per is per to deduct

            setDiscount(
              `${
                result.couponDetails?.percent_off == null ? '£' + amountOff : result.couponDetails?.percent_off + '%'
              }`,
            );

            const durationInMonths: string =
              result.couponDetails?.duration == 'once' ? '1' : result.couponDetails?.duration_in_months;
            setDuration(durationInMonths);

            const durationPeriodVal: string = result.couponDetails?.duration;
            setDurationPeriod(durationPeriodVal);

            const discountAmount: number =
              amountOff == null ? +subtotal * (result.couponDetails?.percent_off / 100) : amountOff;
            // Calculating the discounted price
            const discountedPrice: number = +subtotal - discountAmount;

            if (result.couponDetails?.amount_off != null) {
              setTotalValue(discountedPrice);
            } else {
              setTotalValue(discountedPrice);
            }
            setCouponCode(result.couponDetails.id);
            return;
          }
          else
          {
              //not a valid code
              errorToast(`Invalid Coupon: ${result?.message}`);
          }

        }
        setDiscount('');
        setCouponCode('');
        setApplyClick(false);
        setTotalValue(+plan?.price);
        setDuration('');
        setDurationPeriod('');
        

        // const response = await axios.post('/api/apply-coupon', { couponCode });
        // const { subtotal, discount, totalValue } = response.data;
        // setSubtotal(subtotal);
        // setDiscount(discount);
        // setTotalValue(totalValue);
      } catch (error) {
        errorToast('Invalid Coupon');
        setDiscount('');
        setCouponCode('');
        setDuration('');
        setDurationPeriod('');
        setApplyClick(false);
        setTotalValue(+plan?.price);
        console.error('Coupon application failed:', error);
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      togglePayBtn('submitBtn',true);
      return;
    }

    const { token, error } = await stripe.createToken(elements.getElement(CardNumberElement));
    if (error !== undefined) {
      errorToast('Payment Error: ' + error.message);
      togglePayBtn('submitBtn',true);
      //error so cancel out of this process
      return;
    }

    const request: any = token;
    request.planSelected = selectedPlanId; // 'venflow360';
    request.name = cardholderName;
    request.email = email; //'test@test.com';
    request.clientId = clientId;
    if (couponCode) {
      request.couponCode = couponCode;
    }

    setLoading(true);
    try {
      const response: any = await dispatch(createInitialStripeSetup(request));
      setLoading(false);
      if (response) {
        if (response.result.success) {
          successToast('Payment Successful');
          onChangeStage(Stage.Connectors);
        } else {
          errorToast(`Payment Failed. Reason: ${response?.data?.result?.status ?? response.result.message}`);
          togglePayBtn('submitBtn',true);
        }
      }
    } catch (error: any) {
      setLoading(false);
      togglePayBtn('submitBtn',true);
      errorToast(`Payment Failed: ${error?.response.data.error_description}`);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <div className="form-left">
        <div className="form-group">
          <label>Cardholder Name</label>
          <input
            type="text"
            name="cardholderName"
            className="card-element"
            placeholder="John Doe"
            value={cardholderName}
            onChange={handleInputChange}
            style={{ marginBottom: '3%' }}
          />
        </div>
        <div className="form-group">
          <label>Card Number </label>
          <CardNumberElement className="card-element" />
        </div>
        <div className="form-group">
          <label>Expiry Date</label>
          <CardExpiryElement className="card-element" />
        </div>
        <div className="cardStyle">
          <div className="form-group cardWidth">
            <label>CVC</label>
            <CardCvcElement className="card-element" />
          </div>
          <div className="form-group cardWidth" style={{ marginLeft: '1%' }}>
            <label>Zip/PostalCode</label>
            <input type="text" name="zipcode" placeholder="00000" />
          </div>
        </div>
        <div className="cardStyle">
          <div className="form-group cardWidth">
            <label>Coupon Code</label>
            <input
              id="coupon-input"
              type="text"
              className="card-element"
              name="couponCode"
              placeholder="Enter coupon code"
              value={couponCode}
              onChange={e => setCouponCode(e.target.value)}
            />
          </div>

          <button
            type="button"
            onClick={applyCoupon}
            className="apply-coupon-button"
            style={{ marginTop: couponInputHeight - 10 }}
          >
            Apply Coupon
          </button>
        </div>
        <div className="form-group" style={{ marginTop: '4%' }}>
          <label>Billing Details</label>
          <div className="billing-rows">
            <div className="billing-row">
              <span>Subtotal:</span>
              <span>£{subtotal}</span>
            </div>
            <div className="billing-row">
              <span>Discount:</span>
              <span>{discount == '' ? '-' : discount}</span>
            </div>
            {duration !== '' && duration !== null && durationPeriod !== 'forever'? (
              <div className="billing-row" style={{ justifyContent:'center'}}>
                <span>
                  This discount applies for {duration} {duration === '1' ? 'month' : 'months'}!
                </span>
              </div>
            ) : null}
            {durationPeriod === 'forever'? (
              <div className="billing-row" style={{ justifyContent:'center'}}>
                <span>
                  This discount applies for as long as you are a customer!
                </span>
              </div>
            ) : null}
            <div className="billing-row total">
              <span>Total:</span>
              <span>£{totalValue}</span>
            </div>
          </div>
        </div>
        <div className="action-buttons-container">
          {!applyClicked && couponCode.trim() !== '' ? (
            <button className="disable-button" disabled>
              Pay
            </button>
          ) : (
            <button type="submit" className="pay-button" id='submitBtn' onClick={() => togglePayBtn('submitBtn')} disabled={!applyClicked && couponCode.trim() !== '' && payBtnClicked}>
              Pay
            </button>
          )}
        </div>
      </div>

      {/* <div className="form-right"></div> */}

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </form>
  );
};

export default Checkout;
