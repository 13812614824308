import { CssBaseline, ThemeProvider } from '@mui/material';
import React, { FC } from 'react';

import theme from '../assets/theme/theme';

interface Props {
  children: React.ReactNode;
}

export const Layout: FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>{children}</main>
    </ThemeProvider>
  );
};
