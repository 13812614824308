import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RegistrationStageSlice } from './types';
import { Stage } from '../../../types/account/account';

const initialState: RegistrationStageSlice = {
  stage: Stage.AccountType,
  loading: true,
};

export const registrationStageSlice = createSlice({
  name: 'registration-stage',
  initialState,
  reducers: {
    registrationStageProcess: state => {
      state.loading = true;
    },
    registrationStageSuccess: (state, action: PayloadAction<Stage>) => {
      state.stage = action.payload;
      state.loading = false;
    },
    registrationStageFailed: state => {
      state.loading = false;
    },
  },
});
