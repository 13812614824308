import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Link, Outlet } from 'react-router-dom';

import classes from './CompanyLayout.module.css';
import logo from '../../assets/images/logo.svg';
import { ButtonState, SideButton } from '../../components/sideButton/SideButton';
import { registrationStageSelectors } from '../../store/entities/registration-stage';
import { useAppSelector } from '../../store/hooks';
import { Stage } from '../../types/account/account';
import { bool } from 'yup';

export const CompanyLayout = () => {
  const stage = useAppSelector(registrationStageSelectors.stage);

  const getButtonState = (): ButtonState[] => {
    switch (stage) {
      case Stage.AccountType: {
        return [ButtonState.Active, ButtonState.Active, ButtonState.Disabled];
      }

      case Stage.Company: {
        return [ButtonState.Disabled, ButtonState.Active, ButtonState.Active];
      }

      case Stage.WebsiteDetail: {
        return [ButtonState.Active, ButtonState.Disabled, ButtonState.Disabled];
      }

      case Stage.Shop: {
        return [ButtonState.Completed, ButtonState.Completed, ButtonState.Active];
      }

      case Stage.Plans: {
        return [ButtonState.Completed, ButtonState.Completed, ButtonState.Active];
      }

      default: {
        return [ButtonState.Disabled, ButtonState.Disabled, ButtonState.Disabled];
      }
    }
  };

  if (localStorage.getItem('marketingonly') == '0' && stage == Stage.Shop) {
    return (
      <Grid container component="div" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={10} md={10} component={Paper} elevation={6} square>
          <Link to={'/'}>
            <img src={logo} className={classes.Logo} alt="logo" />
          </Link>
          <Grid container alignItems={'center'}>
            <Grid item className={classes.AccountVariant}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={false}
          sm={2}
          md={2}
          sx={{
            backgroundImage: 'linear-gradient(282.4deg, #D40000 -111.32%, #F7B500 44.81%)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            alignItems: 'center',
            height: '100vh',
            display: 'flex',
            position: 'fixed',
            width: '100%',
            right: 0,
          }}
        >
          {/* <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}> */}
          <Box sx={{ paddingTop: '2rem'}}>
            <Box>
              <SideButton itemKey={1} title={'Account Type'} state={ButtonState.Completed} />
              <SideButton itemKey={2} title={'Company'} state={ButtonState.Completed} />
              <SideButton itemKey={3} title={'Link Shop'} state={ButtonState.Active} />
              <SideButton itemKey={4} title={'Plan'} state={getButtonState()[5]} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (localStorage.getItem('marketingonly') == '0' && stage == Stage.Plans) {
    return (
      <Grid container component="div" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={10} md={10} component={Paper} elevation={6} square>
          <Link to={'/'}>
            <img src={logo} className={classes.Logo} alt="logo" />
          </Link>
          <Grid container alignItems={'center'}>
            <Grid item className={classes.AccountVariant}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={false}
          sm={2}
          md={2}
          sx={{
            backgroundImage: 'linear-gradient(282.4deg, #D40000 -111.32%, #F7B500 44.81%)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            alignItems: 'center',
            height: '100vh',
            display: 'flex',
            position: 'fixed',
            width: '100%',
            right: '0',
          }}
        >
          {/* <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}> */}
          <Box sx={{ paddingTop: '284px' }}>
            <Box>
              <SideButton itemKey={1} title={'Account Type'} state={getButtonState()[1]} />
              <SideButton itemKey={2} title={'Company'} state={getButtonState()[0]} />
              <SideButton itemKey={3} title={'Link Shop'} state={getButtonState()[0]} />
              <SideButton itemKey={4} title={'Plan'} state={getButtonState()[2]} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (localStorage.getItem('marketingonly') == '1' && stage == Stage.Plans) {
    return (
      <Grid container component="div" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={10} md={10} component={Paper} elevation={6} square>
          <Link to={'/'}>
            <img src={logo} className={classes.Logo} alt="logo" />
          </Link>
          <Grid container alignItems={'center'}>
            <Grid item className={classes.AccountVariant}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={false}
          sm={2}
          md={2}
          sx={{
            backgroundImage: 'linear-gradient(282.4deg, #D40000 -111.32%, #F7B500 44.81%)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            alignItems: 'center',
            height: '100vh',
            display: 'flex',
            position: 'fixed',
            width: '100%',
            right: '0',
          }}
        >
          {/* <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}> */}
          <Box sx={{ paddingTop: '284px' }}>
            <Box>
              <SideButton itemKey={1} title={'Account Type'} state={getButtonState()[1]} />
              <SideButton itemKey={2} title={'Company'} state={getButtonState()[0]} />
              <SideButton itemKey={3} title={'Website Detail'} state={getButtonState()[0]} />
              <SideButton itemKey={4} title={'Plan'} state={getButtonState()[2]} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (stage == Stage.Company && localStorage.getItem('marketingonly') == '0') {
    return (
      <Grid container component="div" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={10} md={10} component={Paper} elevation={6} square>
          <Link to={'/'}>
            <img src={logo} className={classes.Logo} alt="logo" />
          </Link>
          <Grid container alignItems={'center'}>
            <Grid item className={classes.AccountVariant}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={false}
          sm={2}
          md={2}
          sx={{
            backgroundImage: 'linear-gradient(282.4deg, #D40000 -111.32%, #F7B500 44.81%)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            alignItems: 'center',
            height: '100vh',
            display: 'flex',
            position: 'fixed',
            width: '100%',
            right: '0',
          }}
        >
          {/* <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}> */}
          <Box sx={{ paddingTop: '284px' }}>
            <Box>
              <SideButton itemKey={1} title={'Account Type'} state={ButtonState.Completed} />
              <SideButton itemKey={2} title={'Company'} state={getButtonState()[2]} />
              <SideButton itemKey={3} title={'Link Shop'} state={getButtonState()[3]} />
              <SideButton itemKey={4} title={'Plan'} state={getButtonState()[4]} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (stage == Stage.Company && localStorage.getItem('marketingonly') == '1') {
    return (
      <Grid container component="div" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={10} md={10} component={Paper} elevation={6} square>
          <Link to={'/'}>
            <img src={logo} className={classes.Logo} alt="logo" />
          </Link>
          <Grid container alignItems={'center'}>
            <Grid item className={classes.AccountVariant}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={false}
          sm={2}
          md={2}
          sx={{
            backgroundImage: 'linear-gradient(282.4deg, #D40000 -111.32%, #F7B500 44.81%)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            alignItems: 'center',
            height: '100vh',
            display: 'flex',
            position: 'fixed',
            width: '100%',
            right: '0',
          }}
        >
          {/* <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}> */}
          <Box sx={{ paddingTop: '284px' }}>
            <Box>
              <SideButton itemKey={1} title={'Account Type'} state={ButtonState.Completed} />
              <SideButton itemKey={2} title={'Company'} state={getButtonState()[2]} />
              <SideButton itemKey={3} title={'Website Detail'} state={ButtonState.Disabled} />
              <SideButton itemKey={4} title={'Plan'} state={ButtonState.Disabled} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
  if (stage == Stage.WebsiteDetail) {
    return (
      <Grid container component="div" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={10} md={10} component={Paper} elevation={6} square>
          <Link to={'/'}>
            <img src={logo} className={classes.Logo} alt="logo" />
          </Link>
          <Grid container alignItems={'center'}>
            <Grid item className={classes.AccountVariant}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={false}
          sm={2}
          md={2}
          sx={{
            backgroundImage: 'linear-gradient(282.4deg, #D40000 -111.32%, #F7B500 44.81%)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            alignItems: 'center',
            height: '100vh',
            display: 'flex',
            position: 'fixed',
            width: '100%',
            right: '0',
          }}
        >
          {/* <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}> */}
          <Box sx={{ paddingTop: '284px' }}>
            <Box>
              <SideButton itemKey={1} title={'Account Type'} state={ButtonState.Completed} />
              <SideButton itemKey={2} title={'Company'} state={ButtonState.Completed} />
              <SideButton itemKey={3} title={'Website Detail'} state={getButtonState()[0]} />
              <SideButton itemKey={4} title={'Plan'} state={ButtonState.Disabled} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container component="div" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={10} md={10} component={Paper} elevation={6} square>
          <Link to={'/'}>
            <img src={logo} className={classes.Logo} alt="logo" />
          </Link>
          <Grid container alignItems={'center'}>
            <Grid item className={classes.AccountVariant}>
              <Outlet />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={false}
          sm={2}
          md={2}
          sx={{
            backgroundImage: 'linear-gradient(282.4deg, #D40000 -111.32%, #F7B500 44.81%)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            alignItems: 'center',
            height: '100vh',
            display: 'flex',
            position: 'fixed',
            width: '100%',
            right: '0',
          }}
        >
          {/* <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}> */}
          <Box sx={{ paddingTop: '284px' }}>
            <Box>
              <SideButton itemKey={1} title={'Account Type'} state={getButtonState()[0]} />
              <SideButton itemKey={2} title={'Company'} state={getButtonState()[2]} />
              <SideButton itemKey={3} title={'Link Shop'} state={getButtonState()[2]} />
              <SideButton itemKey={4} title={'Plan'} state={getButtonState()[2]} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
};
