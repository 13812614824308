import { useSnackbar } from 'notistack';

export const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  return {
    successToast: (message: string) => enqueueSnackbar(message, { variant: 'success' }),
    errorToast: (message: string = 'Something went wrong!') => enqueueSnackbar(message, { variant: 'error' }),
    warningToast: (message: string) => enqueueSnackbar(message, {variant: 'warning'})
  };
};
