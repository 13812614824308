import { Button, Link } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { IBaseComponentProps } from '../../../../components/IBaseComponentProps';
import { RoutePaths } from '../../../../containers/RoutePaths';

export const NewUserConfirmation = (props: IBaseComponentProps) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Typography component="div" variant="h3" className={props.classes.heading} style={{ marginBottom: '40px' }}>
        Your account <br />
        <b>created succesfully!</b>
      </Typography>
      <Typography component="div">Let’s connect your company details and data.</Typography>
      <Box component="form" noValidate sx={{ mt: 5 }}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => navigate(`/${RoutePaths.Company}/${RoutePaths.CompanyRegistration}`)}
        >
          Start Now
        </Button>
      </Box>
      <Box className={props.classes.LocalFooter}>
        <hr />
        By clicking you’re agreeing to our&nbsp;
        <a href="https://www.vennflow.com/terms-of-service" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        .
      </Box>
    </Fragment>
  );
};
