import { Response } from '../../types/api/api';
import { FeatureData, GetSystemAccessIdParams } from '../../types/connectors/connectors';
import { SelectedFeatures } from '../../types/connectors/facebook-data';
import { baseApi } from '../api';

const getInstallLink = () =>
  baseApi.get(`FacebookConnector/AuthUrl?redirectUri=${process.env.REACT_APP_FACEBOOK_REDIRECT_URL}`);

const getAdAccount = (systemAccessId: string) =>
  baseApi.get<Response<FeatureData[]>>(`facebookconnector/adAccounts/${systemAccessId}`);

const getSystemAccessId = (params: GetSystemAccessIdParams) =>
  baseApi.get('facebookconnector/exchangeAccessCodeForAccessToken', { params });

const saveSelection = (
  clientId: string,
  clientBrandId: string,
  clientBrandStoreId: string,
  selectedFeatures: SelectedFeatures,
) =>
  baseApi.post<Response<FeatureData[]>>('facebookConnector/analyticsFeatures', {
    clientId,
    systemId: '1209606E-1F5A-4400-9CFA-59620E4D12C7',
    clientBrandId,
    clientBrandStoreId,
    features: [
      {
        id: 'adAccountId',
        value: selectedFeatures.adAccount?.id,
      },
    ],
  });

export const facebookApi = {
  getInstallLink,
  getAdAccount,
  getSystemAccessId,
  saveSelection,
};
