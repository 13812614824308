import { useToast } from 'hooks/use-toast';
import jwtDecode from 'jwt-decode';
import { useEffect, Fragment } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUser } from 'store/entities/account';

import { RoutePaths } from '../../../containers/RoutePaths';
import { updateRegistrationStage } from '../../../store/entities/registration-stage/actions';
import { addClientBrandStore, exchangeAccessCode } from '../../../store/entities/shopify';
import { useAppDispatch } from '../../../store/hooks';
import { Stage, UserFromToken } from '../../../types/account/account';

export const ShopifyConnectorPage = () => {
  const { errorToast } = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  function cleanseStoreName(url:string):string
  {
    //remove http(s)
    const str = url.replace(/^(https?:\/\/)?/,'');
    //split the rest of the string to only take the first part of the url if passed in
    return str.split('.')[0];
  } 

  useEffect(() => {
    const init = async () => {
      const accessToken = localStorage.getItem('accessToken');

      const storeName = cleanseStoreName(localStorage.getItem('storeName'));
      const storeIdentifier = cleanseStoreName(localStorage.getItem('storeIdentifier'));
      const systemId = '17213426-046B-4C11-A1D3-4AD25E3C1273';
      const code = searchParams.get('code');

      try {
        const { UserId: userId }: UserFromToken = jwtDecode(accessToken as string);

        const { clientId, brandList } = await dispatch(getUser(userId));
        const brandId = brandList[0].Id;

        const brandStoreId = await dispatch(
          addClientBrandStore({
            clientId: clientId,
            clientBrandId: brandId,
            storeName: storeName as string,
            storeIdentifier: storeIdentifier as string,
            systemId,
            storeDescription: '',
            storeCurrencyUsed: 'GBP',
          }),
        );

        await dispatch(
          exchangeAccessCode(
            {
              clientId: clientId,
              clientBrandId: brandId,
              clientBrandStoreId: brandStoreId,
              shopId: storeIdentifier as string,
            },
            code as string,
          ),
        );

        dispatch(
          updateRegistrationStage(
            {
              userId,
              stage: Stage.Plans,
            },
            errorToast,
          ),
        );
      } catch {
        errorToast();
      }

      navigate(`/${RoutePaths.Company}/${RoutePaths.CompanyRegistration}`);
    };

    init();
  }, []);

  return <Fragment>Redirecting...</Fragment>;
};
