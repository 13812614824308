import { Box, Grid, Typography, Button, Card, CardContent, Link, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useToast } from 'hooks/use-toast';
import jwtDecode from 'jwt-decode';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'store/entities/account';
import { accountSelectors } from 'store/entities/account/selectors';

import { ConnectorCard } from './connectors/ConnectorCard';
import { FacebookDataSelection } from './connectors/FacebookDataSelection';
import { GoogleAnalyticsDataSelection } from './connectors/GoogleAnalyticsDataSelection';
import { TriggerPopupWindow as triggerPopupWindow } from './connectors/PopupAuthenticationWindow';
import AmazonIcon from '../../../../assets/icons/amazonicon.svg';
import ebayIcon from '../../../../assets/icons/ebayicon.svg';
import FBIcon from '../../../../assets/icons/fbicon.svg';
import GoogleAnalyticsIcon from '../../../../assets/icons/gaIcon.svg';
import tiktokIcon from '../../../../assets/icons/tiktokicon.svg';
import snapchatIcon from '../../../../assets/icons/snapchat.svg';
import { CustomDialog } from '../../../../components/dialog/CustomDialog';
import { RoutePaths } from '../../../../containers/RoutePaths';
import {
  getInstallLink as getFacebookInstallLink,
  getSystemAccessId as getFacebookSystemAccessId,
} from '../../../../store/entities/facebook';
import {
  getInstallLink as getGoogleInstallLink,
  getInstallLinkGA4 as getGoogleGA4InstallLink,
  getSystemAccessId as getGoogleSystemAccessId,
  getSystemAccessIdGA4 as getGoogleSystemAccessIdGA4,
} from '../../../../store/entities/google-analytics';
import { ConnectedSystems } from 'store/entities/company/helper/map-connected-systems';
import { updateRegistrationStage } from '../../../../store/entities/registration-stage/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Stage, UserFromToken } from '../../../../types/account/account';
import { DataConnectorsCardGA } from './connectors/ConnectorCardGA';
import { getSystemAccessIdByClientId } from 'store/entities/google-analytics/actions';
import { GoogleAnalytics4DataSelection } from './connectors/GoogleAnalytics4DataSelection';

export interface Props {
  classes: any;
  connectedSystems?: ConnectedSystems;
}

export const ConnectorsLink: FC<Props> = ({ classes, connectedSystems }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { successToast, errorToast } = useToast();

  const [isGoogleModalOpen, setIsGoogleModalOpen] = useState(false);
  const [isGA4, setGoogleAnalyticsState] = useState(false);

  const [isFacebookModalOpen, setIsFacebookModalOpen] = useState(false);
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
  const [isGoogleAnalyticsConnected, setIsGoogleAnalyticsConnected] = useState(false);
  const [isGoogleAnalytics4Connected, setIsGoogleAnalytics4Connected] = useState(false);

  const [isFacebookConnected, setIsFacebookConnected] = useState(false);

  const [isGoogleAnalytics4ConnectorComplete, setIsGoogleAnalytics4ConnectorComplete] = useState(false);

  const [isGoogleAnalyticsConnectorComplete, setIsGoogleAnalyticsConnectorComplete] = useState(false);
  const [isFacebookConnectorComplete, setIsFacebookConnectorComplete] = useState(false);

  const [isGoogleAnalyticsPopupClosed, setIsGoogleAnalyticsPopupClosed] = useState(false);
  const [isFacebookPopupClosed, setIsFacebookPopupClosed] = useState(false);

  const [googleSystemAccessId, setGoogleSystemAccessId] = useState('');
  const [facebookSystemAccessId, setFacebookSystemAccessId] = useState('');

  const { isShopifyReg } = useAppSelector(accountSelectors.user);
  const loading = useAppSelector(accountSelectors.loading);
  const clientId = useAppSelector(accountSelectors.clientId);
  const brandId = useAppSelector(accountSelectors.brandId);
  const brandStoreId = useAppSelector(accountSelectors.brandStoreId);
  const userId = useAppSelector(accountSelectors.userId);
  const Ismarketing = localStorage.getItem('marketingonly');

  const fireGoogleAnalyticsConnector = async () => {
    const gaInstallLink = await dispatch(getGoogleInstallLink(errorToast));

    triggerPopupWindow(gaInstallLink, 'google analytics authentication', async () => {
      setIsGoogleAnalyticsPopupClosed(true);

      try {
        const code = localStorage.getItem('gacode');
        const storeId = localStorage.getItem('brandStoreId');

        const { clientSystemAccessId: systemAccessId } = await dispatch(
          getGoogleSystemAccessId({
            accessCode: code as string,
            clientId,
            clientBrandId: brandId,
            clientBrandStoreId:  (brandStoreId == null || brandStoreId == '') ? storeId : brandStoreId,
            redirectUri: process.env.REACT_APP_GOOGLE_REDIRECT_URL as string,
          }),
        );
        setIsGoogleAnalytics4Connected(false);

        setIsGoogleAnalyticsConnected(true);
        setGoogleSystemAccessId(systemAccessId);
        setIsGoogleModalOpen(true);
      } catch {
        errorToast('Google Universal Analytics Data Connector needs configuration');
      }
    });
  };

  const fireGoogleAnalyticsConnectorGA4 = async () => {
    try {
      const gaInstallLink = await dispatch(getGoogleGA4InstallLink());

      triggerPopupWindow(gaInstallLink, 'google analytics authentication', async () => {
        setGoogleAnalyticsState(true);
        try {
          const code = localStorage.getItem('ga4code');
          const storeId = localStorage.getItem('brandStoreId');

          const { clientSystemAccessId: systemAccessId } = await dispatch(
            getGoogleSystemAccessIdGA4({
              isGA4: true,
              accessCode: code,
              clientId,
              clientBrandId: brandId,
              clientBrandStoreId: (brandStoreId == null || brandStoreId == '') ? storeId : brandStoreId,
              redirectUri: process.env.REACT_APP_GOOGLEGA4_REDIRECT_URL,
            }),
          );
          setIsGoogleAnalyticsConnected(false);

          setIsGoogleAnalytics4Connected(true);
          setGoogleSystemAccessId(systemAccessId);
          setIsGoogleModalOpen(true);
        } catch {
          errorToast('Google Analytics 4 Data Connector needs configuration');
        }
      });
    } catch {
      errorToast();
    }
  };
  const fireFacebookConnector = async () => {
    const fbInstallLink = await dispatch(getFacebookInstallLink(errorToast));

    triggerPopupWindow(fbInstallLink, 'facebook authentication', async () => {
      setIsFacebookPopupClosed(true);

      try {
        const code = localStorage.getItem('fbcode');
        const storeId = localStorage.getItem('brandStoreId');

        const { clientSystemAccessId: systemAccessId } = await dispatch(
          getFacebookSystemAccessId({
            accessCode: code as string,
            clientId,
            clientBrandId: brandId,
            clientBrandStoreId:  (brandStoreId == null || brandStoreId == '') ? storeId : brandStoreId,
            redirectUri: process.env.REACT_APP_FACEBOOK_REDIRECT_URL as string,
          }),
        );

        setFacebookSystemAccessId(systemAccessId);
        setIsFacebookConnected(true);
        setIsFacebookModalOpen(true);
      } catch {
        errorToast('Facebook Ads Data Connector needs configuration');
      }
    });
  };

  const handleFacebookDialogClose = () => {
    setIsFacebookModalOpen(false);

    errorToast('Facebook Ads Data Connector needs configuration');
  };

  const handleGoogleDialogClose = () => {
    setIsGoogleModalOpen(false);

    errorToast('Google Universal Analytics Data Connector needs configuration');
  };

  const handleFinishDialogClose = () => {
    setIsFinishModalOpen(false);
  };

  const onFinishButtonClick = () => {
    setIsFinishModalOpen(true);
  };

  const onGoogleAnalyticsClick = () => {
    if (isGoogleAnalyticsPopupClosed) {
      setIsGoogleModalOpen(true);
    }
  };
  const onFacebookClick = () => {
    if (isFacebookPopupClosed) {
      setIsFacebookModalOpen(true);
    }
  };

  const handleGoogleAnalyticsFeatureSelection = () => {
    setIsGoogleModalOpen(false);
    setIsGoogleAnalyticsConnectorComplete(true);
  };

  const handleGoogleAnalytics4FeatureSelection = () => {
    setIsGoogleModalOpen(false);
    setIsGoogleAnalytics4ConnectorComplete(true);
  };

  const handleFacebookFeatureSelection = () => {
    setIsFacebookModalOpen(false);
    setIsFacebookConnectorComplete(true);
  };

  const navigateToFinish = () => {
    dispatch(updateRegistrationStage({ stage: Stage.Finished, userId: userId as string }, errorToast));

    navigate(`../../${RoutePaths.Account}/${RoutePaths.Finish}`);
  };

  const openGoogleFeaturesModal = async () => {
    try {
      const result = await dispatch(getSystemAccessIdByClientId(clientId));

      const systemAccessId = result.find(
        el => el.systemName === 'Google Analytics UA' && el.clientBrandStoreId === brandStoreId,
      ).Id;
      setIsGoogleAnalyticsConnected(true);
      setGoogleSystemAccessId(systemAccessId);
      setIsGoogleModalOpen(true);
      setGoogleAnalyticsState(false);

      // });
    } catch {
      errorToast();
    }
  };

  const openGoogle4FeaturesModal = async () => {
    try {
      const result = await dispatch(getSystemAccessIdByClientId(clientId));

      const systemAccessId = result.find(
        el => el.systemName === 'Google Analytics 4' && el.clientBrandStoreId === brandStoreId,
      ).Id;

      setIsGoogleAnalyticsConnected(true);
      setGoogleSystemAccessId(systemAccessId);
      setIsGoogleModalOpen(true);
      setGoogleAnalyticsState(true);
    } catch {
      errorToast();
    }
  };
  return (
    <Fragment>
      {isGoogleModalOpen && isGoogleAnalyticsConnected ? (
        <>
          <GoogleAnalyticsDataSelection
            open={isGoogleModalOpen && isGoogleAnalyticsConnected}
            systemAccessId={googleSystemAccessId}
            onFeaturesSelected={handleGoogleAnalyticsFeatureSelection}
            onFeaturesSelectedGA4={handleGoogleAnalytics4FeatureSelection}
            onClose={handleGoogleDialogClose}
          />
        </>
      ) : (
        <>
          <GoogleAnalytics4DataSelection
            open={isGoogleModalOpen && isGoogleAnalytics4Connected}
            systemAccessId={googleSystemAccessId}
            onFeaturesSelected={handleGoogleAnalyticsFeatureSelection}
            onFeaturesSelectedGA4={handleGoogleAnalytics4FeatureSelection}
            onClose={handleGoogleDialogClose}
          />
        </>
      )}

      <FacebookDataSelection
        open={isFacebookModalOpen && isFacebookConnected}
        systemAccessId={facebookSystemAccessId}
        onFeaturesSelected={handleFacebookFeatureSelection}
        onClose={handleFacebookDialogClose}
      />
      <Box component="div">
        <Typography component="div" variant="h3" gutterBottom>
          {Ismarketing === '1' ? <div> Your Company is now Linked</div> : <div>Your Shop is now Linked </div>}
        </Typography>
        <Typography component="div" variant="body2">
          Now it’s time to link your other data sources to Vennflow!
        </Typography>
        <Typography component="div" variant="body2" sx={{ marginBottom: '1rem' }}>
          Connecting your Google Analytics Account and your Facebooks Ad Account allows Vennflow to make your data work
          for you!
        </Typography>
        <Typography component="div" variant="body2" sx={{ marginBottom: '1.5rem' }}>
          Simply Click connect and you will be taken to the needed platform to allow Vennflow access.
        </Typography>

        <Link href="https://www.vennflow.com/faq" target="_blank" rel="noreferrer">
          Need help understanding the Connectors and how to allow access? View out FAQ
        </Link>

        <Card className={`${classes.PlanCardBase} ${classes.SelectedPlanCard}`} sx={{ marginTop: '1.5rem' }}>
          <CardContent sx={{ minHeight: '10rem' }} className={`${classes.PlanCardHeader}`}>
            {loading ? (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              </Grid>
            ) : (
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                 <Grid item xs={2}>
                  <DataConnectorsCardGA
                    name={'Google Analytics 4'}
                    icon={GoogleAnalyticsIcon}
                    onClickSettings={openGoogle4FeaturesModal}
                    onClick={fireGoogleAnalyticsConnectorGA4}
                    connected={connectedSystems?.googlega4 || isGoogleAnalytics4ConnectorComplete}
                    isConnectorComplete={isGoogleAnalytics4ConnectorComplete}
                    variant="google"
                    compId="gaua"
                  />
                </Grid>
                <Grid item xs={2}>
                  <ConnectorCard
                    classes={classes}
                    cardName={'Facebook and Instagram Ads'}
                    cardIcon={FBIcon}
                    onCardClick={onFacebookClick}
                    onConnectClick={fireFacebookConnector}
                    isConnected={isFacebookConnected}
                    isConnectorComplete={isFacebookConnectorComplete}
                    variant="facebook"
                  />
                </Grid>
                <Grid item xs={2}>
                  <DataConnectorsCardGA
                    name={'Google Universal Analytics'}
                    icon={GoogleAnalyticsIcon}
                    onClickSettings={openGoogleFeaturesModal}
                    onClick={fireGoogleAnalyticsConnector}
                    connected={connectedSystems?.google || isGoogleAnalyticsConnectorComplete}
                    isConnectorComplete={isGoogleAnalyticsConnectorComplete}
                    variant="google"
                    compId="gaua"
                  />
                </Grid>
                <Grid item xs={2}>
                  <DataConnectorsCardGA
                    name={'Tiktok Ads'}
                    icon={tiktokIcon}
                    onClickSettings={undefined}
                    onClick={undefined}
                    connected={false}
                    isConnectorComplete={undefined}
                    variant="default"
                    compId="tiktok"
                    disabled
                    custombuttonlabel="Setup within App"
                    customtooltip="You can connect Snapchat within the App"
                  />
                </Grid>
                <Grid item xs={2}>
                  <DataConnectorsCardGA
                    name={'Snapchat Ads'}
                    icon={snapchatIcon}
                    onClickSettings={undefined}
                    onClick={undefined}
                    connected={false}
                    isConnectorComplete={undefined}
                    variant="default"
                    compId="snapchat"
                    disabled
                    custombuttonlabel="Setup within App"
                    customtooltip="You can connect Snapchat within the App"
                  />
                </Grid>
                            
                {/* {!isShopifyReg && (
                  <>
                    <Grid item xs={2}>
                      <ConnectorCard
                        classes={classes}
                        cardName={'Amazon Seller Partner'}
                        cardIcon={AmazonIcon}
                        onCardClick={undefined}
                        onConnectClick={undefined}
                        isConnected={isAmazonConnected}
                        isConnectorComplete={isAmazonConnectorComplete}
                        disabled
                        comingSoon
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <ConnectorCard
                        classes={classes}
                        cardName={'Amazon Ads'}
                        cardIcon={AmazonIcon}
                        onCardClick={undefined}
                        onConnectClick={undefined}
                        isConnected={isAmazonConnected}
                        isConnectorComplete={isAmazonConnectorComplete}
                        disabled
                        comingSoon
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <ConnectorCard
                        classes={classes}
                        cardName={'eBay'}
                        cardIcon={ebayIcon}
                        onCardClick={undefined}
                        onConnectClick={undefined}
                        isConnected={isEbayConnected}
                        isConnectorComplete={isEbayConnectorComplete}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <ConnectorCard
                        classes={classes}
                        cardName={'Tiktok'}
                        cardIcon={tiktokIcon}
                        onCardClick={undefined}
                        onConnectClick={undefined}
                        isConnected={isTikTokConnected}
                        isConnectorComplete={isTikTokConnectorComplete}
                        disabled
                      />
                    </Grid>
                  </>
                )} */}
              </Grid>
            )}
          </CardContent>
        </Card>

        <Box sx={{ marginTop: '10rem' }}>
          <hr />
          <div className={classes.LocalFooterButtons}>
            <CustomDialog
              isModalOpen={isFinishModalOpen}
              ModalHandleClose={handleFinishDialogClose}
              ModalTitle="Finish Setup"
            >
              {isGoogleAnalyticsConnectorComplete || isFacebookConnectorComplete ? (
                <Box component="div">
                  <Typography component="div" variant="body2" sx={{ marginBottom: '2rem' }}>
                    <span className={`${classes.FinishPopupHeading}`}>
                      You've connected your store data successfully. 🎉
                    </span>
                    {/* Would you like to connect more? */}

                    {/* <span className={`${classes.FinishPopupTip}`}>Pro tip: You can always connect more later from the dashboard.</span> */}
                  </Typography>
                  <Grid container justifyContent="center" alignItems="center">
                    {/* <Button
                      variant="outlined"
                      size="small"
                      className={`${classes.ButtonMoreData}`}
                      onClick={handleFinishDialogClose}
                    >I want to connect more</Button> */}
                    <Button
                      onClick={navigateToFinish}
                      variant="contained"
                      color="primary"
                      size="small"
                      className={`${classes.ButtonDashboard}`}
                    >
                      Take me to the dashboard
                    </Button>
                  </Grid>
                </Box>
              ) : (
                <Box component="div">
                  <Typography component="div" variant="body2" sx={{ marginBottom: '2rem' }}>
                    <span className={`${classes.FinishPopupHeading}`}>
                      You have pending steps to connect one or more data for your store
                    </span>

                    <span className={`${classes.FinishPopupTip}`}>
                      Pro tip: If you're in hurry, you can always connect more later from the dashboard.
                    </span>
                  </Typography>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Button
                      onClick={navigateToFinish}
                      variant="outlined"
                      size="small"
                      className={`${classes.ButtonMoreData}`}
                    >
                      Take me to the dashboard
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={`${classes.ButtonDashboard}`}
                      onClick={handleFinishDialogClose}
                    >
                      I want to connect more
                    </Button>
                  </Grid>
                </Box>
              )}
            </CustomDialog>

            <Button color="primary" variant="contained" sx={{ textTransform: 'initial' }} onClick={onFinishButtonClick}>
              Finish &amp; continue to dashboard
            </Button>
          </div>
        </Box>
      </Box>
    </Fragment>
  );
};
