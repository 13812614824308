import * as yup from 'yup';

import { passwordSchema } from '../../../../account/utils/password-schema';

export const shopifyRegistrationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  companyName: yup.string().required('Company name is required'),
  password: passwordSchema,
});
