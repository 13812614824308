import { facebookApi } from '../../../api/facebook';
import { AppDispatch } from '../../../store/store';
import { GetSystemAccessIdParams } from '../../../types/connectors/connectors';
import { SelectedFeatures } from '../../../types/connectors/facebook-data';

export const getInstallLink = (onFailure: () => void) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await facebookApi.getInstallLink();

    return data.result;
  } catch (error) {
    onFailure();
  }
};

export const getAdAccount = (systemAccessId: string) => async () => {
  const { data } = await facebookApi.getAdAccount(systemAccessId);

  return data.result.map((d: any) => ({ id: d.id, value: d.name }));
};

export const getSystemAccessId = (params: GetSystemAccessIdParams) => async () => {
  const { data } = await facebookApi.getSystemAccessId(params);

  return data.result;
};

export const saveSelection =
  (clientId: string, clientBrandId: string, clientBrandStoreId: string, selectedFeatures: SelectedFeatures) =>
  async () => {
    const { data } = await facebookApi.saveSelection(clientId, clientBrandId, clientBrandStoreId, selectedFeatures);

    return data.result;
  };
