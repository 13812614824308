import { companySlice } from './slice';
import { companyApi } from '../../../api/company';
import { ClientBrandStore, CreateClientMaperRequest, RegistrationRequest, clientSystemAccessRequest } from '../../../types/company/company';
import { AppDispatch } from '../../store';

export const companyRegister = (companyData: RegistrationRequest) => async (dispatch: AppDispatch) => {
  dispatch(companySlice.actions.companyRegisterProcess());
  try {
    const { data } = await companyApi.registration(companyData);
    const clientId = data.result;

    dispatch(companySlice.actions.companyRegisterSuccess());

    return clientId;
  } catch (error) {
    dispatch(companySlice.actions.companyRegisterFailed());
    throw error;
  }
};

export const addBrand = (clientId: string, brandName: string) => async (dispatch: AppDispatch) => {
  dispatch(companySlice.actions.companyRegisterProcess());
  try {
    const { data } = await companyApi.addBrand(clientId, brandName);
    const brandId = data.result;

    dispatch(companySlice.actions.companyRegisterSuccess());

    return brandId;
  } catch (error) {
    dispatch(companySlice.actions.companyRegisterFailed());
    throw error;
  }
};

export const createClientMaper = (data: CreateClientMaperRequest) => async (dispatch: AppDispatch) => {
  dispatch(companySlice.actions.companyRegisterProcess());

  try {
    await companyApi.createClientMaper(data);

    dispatch(companySlice.actions.companyRegisterSuccess());
  } catch (error) {
    dispatch(companySlice.actions.companyRegisterFailed());
    throw error;
  }
};

export const setPlan = (userId: string, companyId: string, planId: string, onFailure: () => void) => async () => {
  try {
    await companyApi.setPlan(userId, companyId, planId);
  } catch (error) {
    onFailure();
  }
};

export const getPlans = (isShopify: boolean,isMarketing:boolean) => async (dispatch: AppDispatch) => {
  dispatch(companySlice.actions.getPlansProcess());
  try {
    const { data } = await companyApi.getPlans(isShopify,isMarketing);
    if (data) {
      const sorted = data.result.sort((a, b) => (+a.price > +b.price ? 1 : +b.price > +a.price ? -1 : 0));
    }

    dispatch(companySlice.actions.getPlansSuccess(data.result));

    return data.result;
  } catch (error) {
    dispatch(companySlice.actions.getPlansFailed());
  }
};

export const clientBrandStore = (store: ClientBrandStore) => async (dispatch: AppDispatch) => {
  dispatch(companySlice.actions.companyRegisterProcess());
  try {
    const { data } = await companyApi.createBrandStore(store);
    const clientId = data.result;

    dispatch(companySlice.actions.companyRegisterSuccess());

    return clientId;
  } catch (error) {
    dispatch(companySlice.actions.companyRegisterFailed());
    throw error;
  }
};




export const saveclientSystemAccess = (request: clientSystemAccessRequest) => async (dispatch: AppDispatch) => {
  dispatch(companySlice.actions.companyRegisterProcess());
  try {
    const { data } = await companyApi.saveClientSystemAccess(request);
    const result = data.result;

    dispatch(companySlice.actions.companyRegisterSuccess());

    return result;
  } catch (error) {
    dispatch(companySlice.actions.companyRegisterFailed());
    throw error;
  }
};
