import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';

import FinishAnimation from '../../assets/gifs/finish-animation.gif';

export const Finish = () => {
  const navigateToDashboard = () => {
    const accessToken = localStorage.getItem('accessToken');
    localStorage.clear();
    window.open(`${process.env.REACT_APP_REDIRECT_URL}?accessToken=${accessToken}`, '_self');
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" zIndex={1} position="relative">
      <Typography component="div" variant="h3">
        You are now connected!
      </Typography>
      <Typography component="div" color={theme => theme.palette.text.secondary} sx={{ marginBottom: '1.9rem' }}>
        We are currently processing your data!
      </Typography>

      <Box mb={20}>
        <img src={FinishAnimation} alt="Processing data..." />
      </Box>

      <Button
        onClick={navigateToDashboard}
        color="primary"
        variant="contained"
        fullWidth
        style={{ marginBottom: '1.5rem' }}
      >
        Take me to my Dashboards!
      </Button>

      <Typography component="div" color={theme => theme.palette.text.secondary}>
        Whilst you are waiting for your data to load, go to the app and start to get familiar.
      </Typography>
    </Box>
  );
};
