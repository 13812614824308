import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FC, Fragment } from 'react';

import { FeatureData, FeatureDataGA4 } from '../../../../../types/connectors/connectors';
enum GoogleAnalyticsSelectionStep {
  Account = 1,
  Property = 2,
  View = 3,
}
export interface IStepSelectionProps {
  features: FeatureDataGA4[];
  onSelectFeature: any;
  currentStep: number;
}

export const StepSelectionGA4: FC<IStepSelectionProps> = ({ features, onSelectFeature, currentStep }) => {
  const handleSelection = (feature: FeatureDataGA4) => {
    onSelectFeature(feature);
  };

  switch (currentStep) {
    case GoogleAnalyticsSelectionStep.Property:
      return (
        <Fragment>
          {features.map(feature => (
            <Box key={feature.propertyId} component="div">
              <Button
                onClick={() => handleSelection(feature)}
                color="primary"
                variant="outlined"
                size="small"
                sx={{ maxWidth: '100%', width: '100%', marginBottom: '10px', textTransform: 'none' }}
              >
                {feature.propertyDisplayName}
              </Button>
            </Box>
          ))}
        </Fragment>
      );

    case GoogleAnalyticsSelectionStep.Account:
      return (
        <Fragment>
          {features.map(feature => (
            <Box key={feature.name} component="div">
              <Button
                onClick={() => handleSelection(feature)}
                color="primary"
                variant="outlined"
                size="small"
                sx={{ maxWidth: '100%', width: '100%', marginBottom: '10px', textTransform: 'none' }}
              >
                {feature.displayName} ({feature.name})
              </Button>
            </Box>
          ))}
        </Fragment>
      );

    // case GoogleAnalyticsSelectionStep.View:
    //   return <Fragment></Fragment>;
  }
};
