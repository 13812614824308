import { mapConnectedSystems } from './helper/map-connected-systems';
import { CompanyAnalyticState } from './types';


const connectedSystems = (state: CompanyAnalyticState) => mapConnectedSystems(state.companyAnalytic.allBrandData);

const brandDataLoading = (state: CompanyAnalyticState) => state.companyAnalytic.brandLoading;



export const companyAnalyticSelectors = {
 
  connectedSystems,
  brandDataLoading,
  
};
