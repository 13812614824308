import React, { Fragment, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';

import { CookieIdentifiers } from './CookieIdentifiers';

const CookiePage = () => {
  const navigate = useNavigate();
  const params = useParams<{ cookieCode: string; redirectPage: string }>();

  const [, setCookie] = useCookies([CookieIdentifiers.DefaultIdentifier]);

  useEffect(() => {
    if (params.cookieCode) {
      const nextDay = new Date();
      nextDay.setUTCDate(nextDay.getDate() + 1);
      nextDay.setUTCHours(3, 0, 0, 0);
      const expirationDate = new Date(nextDay);
      setCookie(CookieIdentifiers.DefaultIdentifier, params.cookieCode, {
        path: '/',
        expires: expirationDate,
        maxAge: 43200,
      });

      if (params.redirectPage) {
        navigate(`/${params.redirectPage}`);
      } else {
        navigate('/');
      }
    }
  }, [navigate, params, setCookie]);

  return <Fragment />;
};

export default CookiePage;
