import { baseApi } from 'api/api';
import { googleAnalyticsApi } from '../../../api/google-analytics';
import { AppDispatch } from '../../../store/store';
import { GetSystemAccessIdParams, GetSystemAccessIdParamsGA4 } from '../../../types/connectors/connectors';
import { SelectedFeatures, SelectedFeaturesGA4 } from '../../../types/connectors/google-analytics-data';
import { companyApi } from 'api/company';

export const getInstallLink = (onFailure: () => void) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await googleAnalyticsApi.getInstallLink();

    return data.result;
  } catch (error) {
    onFailure();
  }
};

export const getInstallLinkGA4 = () => async () => {
  const { data } = await googleAnalyticsApi.getInstallLinkGA4();

  return data.result;
};

export const getAccounts = (systemAccessId: string) => async () => {
  const { data } = await googleAnalyticsApi.getAccounts(systemAccessId);

  return data.result;
};

export const getProperties = (systemAccessId: string, accountId: string) => async () => {
  const { data } = await googleAnalyticsApi.getProperties(systemAccessId, accountId);

  return data.result;
};

export const getViews = (systemAccessId: string, accountId: string, propertyId: string) => async () => {
  const { data } = await googleAnalyticsApi.getViews(systemAccessId, accountId, propertyId);

  return data.result;
};

export const getPropertiesGA4 = (systemAccessId: string, accountId: string) => async () => {
  const { data } = await googleAnalyticsApi.getPropertiesGA4(systemAccessId, accountId);

  return data.result;
};

export const getViewsGA4 = (systemAccessId: string) => async () => {
  const { data } = await googleAnalyticsApi.getViewsGA4(systemAccessId);

  return data.result;
};

export const getSystemAccessIdGA4 = (params: GetSystemAccessIdParamsGA4) => async () => {
  const { data } = await googleAnalyticsApi.getSystemAccessIdGA4(params);

  return data.result;
};

export const getSystemAccessIdByClientId = (clientId: string) => async () => {
  const { data } = await companyApi.getSystemAccessIdByClientId(clientId);

  return data.result;
};

export const getSystemAccessId = (params: GetSystemAccessIdParams) => async () => {
  const { data } = await googleAnalyticsApi.getSystemAccessId(params);

  return data.result;
};

export const saveSelection =
  (clientId: string, clientBrandId: string, clientBrandStoreId: string, selectedFeatures: SelectedFeatures) =>
  async () => {
    const { data } = await googleAnalyticsApi.saveSelection(
      clientId,
      clientBrandId,
      clientBrandStoreId,
      selectedFeatures,
    );

    return data.result;
  };

export const saveSelectionGA4 =
  (clientId: string, clientBrandId: string, clientBrandStoreId: string, selectedFeatures: SelectedFeaturesGA4) =>
  async () => {
    const { data } = await googleAnalyticsApi.saveSelectionGA4(
      clientId,
      clientBrandId,
      clientBrandStoreId,
      selectedFeatures,
    );

    return data.result;
  };
