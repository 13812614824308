import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

interface PopupProps {
  isModalOpen: boolean;
  ModalHandleClose: any;
  ModalTitle: string;
  children: any;
}

// <button onClick={() => { setOpenPopup(false) }}>Close Card</button>

//"title" of the dialog, "subtitle" of the dialog, "content" of the dialog,
//"isopen" and "handleClose" to handle opening and closing of the dialog

export const CustomDialog = ({ ModalTitle, children, isModalOpen, ModalHandleClose }: PopupProps) => {
  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={ModalHandleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundImage: 'linear-gradient(0deg, #E95E3B -148.44%, #FFB13D 123.44%)',
            padding: '3px 24px',
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6" component="div" sx={{ color: '#9e5f10' }}>
              {ModalTitle}
            </Typography>
            <IconButton onClick={ModalHandleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={ModalHandleClose} sx={{ minWidth: "initial" }}>
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};
