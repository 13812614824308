import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AxiosError } from 'axios';
import { useToast } from 'hooks/use-toast';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { NewUserConfirmation } from './components/NewUserConfirmation';
import { SignUpForm } from './components/SignUpForm';
import classes from './SignUp.module.css';
import theme from '../../../assets/theme/theme';
import { CodeValidation } from '../../../components/code-validation/CodeValidation';
import { accountSlice, addUser } from '../../../store/entities/account';
import { getCompany, verifyCode } from '../../../store/entities/account/actions';
import { accountSelectors } from '../../../store/entities/account/selectors';
import { updateRegistrationStage } from '../../../store/entities/registration-stage/actions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { SignUpFormData, Stage } from '../../../types/account/account';
import { companyRegister, createClientMaper } from 'store/entities/company';
import { addBrand } from 'store/entities/company/actions';

enum SignUpState {
  Form = 0,
  CodeValidation = 1,
  NewUserConfirmation = 2,
}

export const SignUp = () => {
  const dispatch = useAppDispatch();

  const { successToast, errorToast } = useToast();
  const [searchParams] = useSearchParams();

  const loading = useAppSelector(accountSelectors.loading);
  const userId = useAppSelector(accountSelectors.userId);

  const [signUpState, setSignUpState] = useState(SignUpState.Form);

  useEffect(() => {
    const init = async () => {
      const externalUserId = searchParams.get('userId');
      const code = searchParams.get('code');

      try {
        if (externalUserId && code) {
          dispatch(accountSlice.actions.setUserId(externalUserId));

          setSignUpState(SignUpState.CodeValidation);

          await dispatch(verifyCode(externalUserId, code));

          setSignUpState(SignUpState.NewUserConfirmation);

          dispatch(
            updateRegistrationStage(
              {
                userId: userId === null || userId === '' ? externalUserId : userId,
                stage: Stage.AccountType,
              },
              errorToast,
            ),
          );
        }
      } catch (error) {
        const message = error instanceof AxiosError ? error?.response?.data.error_description : 'SWWS003';

        errorToast(message);
      }
    };
    init();
  }, []);

  const onFormSubmit = async (values: SignUpFormData) => {
    try {
      //clear the local storage items
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientBrandId');
      localStorage.removeItem('storeIdentifier');
      localStorage.removeItem('storeName');
      localStorage.removeItem('marketingonly');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('isShopifyRegistration');

      const company = await dispatch(getCompany(values.companyName));

      if (company == 1) {
        errorToast('Company already exist');
        dispatch(accountSlice.actions.failed());
      } else {

        //companyName
        const userId = await dispatch(
          addUser({
            contactEmail: values.contactEmail,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
          }),
        );

        const clientId = await dispatch(
          companyRegister({
            externalId: userId,
            parentExternalId: userId,
            companyName: values.companyName,
            industry: '',
            url: '',
            countryPrefix: '',
            contactNumber: '',
            contactName: '',
            addressLine1: '',
            county: '',
            postcode: '',
            country: '',
            city: '',
            isNonRetail: false,
            isShopifyReg: false,
          }),
        );

        await dispatch(
          createClientMaper({
            externalId: '00000000-0000-0000-0000-000000000000',
            clientId,
            userId,
          }),
        );

        const brandId = await dispatch(addBrand(clientId, values.companyName));
        localStorage.setItem('clientId', clientId);
        localStorage.setItem('clientBrandId', brandId);

        localStorage.setItem('isShopifyRegistration', 'false');

        setSignUpState(SignUpState.CodeValidation);
      }
    } catch (error) {
      const message = error instanceof AxiosError ? error?.response?.data.error_description : 'SWRS001';

      errorToast(message);
    }
  };

  const onVerifyCodeSubmitted = async (code: string) => {
    try {
      await dispatch(verifyCode(userId, code));

      setSignUpState(SignUpState.NewUserConfirmation);

      localStorage.removeItem('marketingonly');
      localStorage.removeItem('storeName');
      localStorage.removeItem('storeIdentifier');
      //localStorage.removeItem('clientId');
      //localStorage.removeItem('clientBrandId');
      localStorage.removeItem('isShopifyRegistration');

      dispatch(
        updateRegistrationStage(
          {
            userId: userId as string,
            stage: Stage.AccountType,
          },
          errorToast,
        ),
      );
    } catch (error) {
      const message = error instanceof AxiosError ? error?.response?.data.error_description : 'SWRS002';

      errorToast(message);
    }
  };

  const render = () => {
    switch (signUpState) {
      case SignUpState.Form: {
        return <SignUpForm loading={loading} classes={classes} onSubmit={onFormSubmit} />;
      }
      case SignUpState.CodeValidation: {
        return <CodeValidation loading={loading} onSubmit={onVerifyCodeSubmitted} />;
      }
      case SignUpState.NewUserConfirmation: {
        return <NewUserConfirmation classes={classes} />;
      }
    }
  };

  return (
    <Box className={classes.ContainerBox}>
      <Typography component="div" color={theme.palette.text.secondary} className={classes.MiniTitle}>
        Welcome to Vennflow
      </Typography>
      {render()}
    </Box>
  );
};
