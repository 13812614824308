import { InputAdornment } from '@mui/material';
import { FC, useState } from 'react';

import { Field } from '../field/Field';
import { StatusIcon } from '../status-icon';
import { VisibleButton } from '../visible-button';

interface Props {
  name: string;
  label: string;
  showAdornment?: boolean;
}

export const PasswordField: FC<Props> = ({ name, label, showAdornment = true }) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const endAdornment = showAdornment ? (
    <InputAdornment position="end">
      <VisibleButton value={isPasswordVisible} onClick={togglePasswordVisibility} />
      <StatusIcon name={name} />
    </InputAdornment>
  ) : null;

  return (
    <Field
      required
      fullWidth
      name={name}
      label={label}
      type={isPasswordVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment,
      }}
    />
  );
};
