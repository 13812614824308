import { LoadingButton } from '@mui/lab';
import { Box, Typography, Button, TextField, Link, IconButton, Checkbox } from '@mui/material';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { shopifySelectors } from 'store/entities/shopify';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import { accountSelectors } from 'store/entities/account/selectors';
import { useToast } from 'hooks/use-toast';
import React from 'react';
import { updateRegistrationStage } from 'store/entities/registration-stage/actions';
import { Stage, UserFromToken } from 'types/account/account';
import { WidthNormal, WidthWideTwoTone } from '@mui/icons-material';
import { clientBrandStore } from 'store/entities/company/actions';
import { getUser } from 'store/entities/account';
import jwtDecode from 'jwt-decode';

export const WebsiteDetail = () => {
  const loading = useAppSelector(shopifySelectors.loading);
  const dispatch = useAppDispatch();
  const userId = useAppSelector(accountSelectors.userId);
  const { errorToast } = useToast();

  const [valueUrl, setUrl] = React.useState('');
  const [valueName, setName] = React.useState('');


  const form = useFormik<any>({
    initialValues: {
      url: '',
      name: '',
    },

    onSubmit: values => {},
  });

  const onchangeName = (event: any) => {
    setName(event.target.value);
  };

  const onchangeUrl = (event: any) => {
    setUrl(event.target.value);
  };

  const onChangeStage = (stage: Stage) => {
    dispatch(updateRegistrationStage({ stage, userId: userId as string }, errorToast));
  };
  const handleSubmit = async () => {
    const withoutSchemeUrl = valueUrl
      .replace(/^https?:\/\//, '')
      .replace(/^http?:\/\//, '')
      .replaceAll('.', '-');
    const storeName = valueName;
    const description = withoutSchemeUrl;


    //const clientID = localStorage.getItem('clientId');
    //const brandID = localStorage.getItem('clientBrandId');
    const accessToken = localStorage.getItem('accessToken');

    const { UserId: userId }: UserFromToken = jwtDecode(accessToken as string);
    const { clientId, brandList } = await dispatch(getUser(userId));
    const brandId = brandList[0].Id;

    console.log(valueName);
    console.log(withoutSchemeUrl);
    console.log(withoutSchemeUrl);

    localStorage.setItem('storeIdentifier', withoutSchemeUrl);

    const store = await dispatch(
      clientBrandStore({
        Id: '00000000-0000-0000-0000-000000000000',
        clientId: clientId,
        clientBrandId: brandId,
        storeName: storeName,
        storeIdentifier: withoutSchemeUrl,

        systemId: 'f65b1ef2-eaf9-4451-8876-3ea24bcceb6a',
        storeDescription: valueUrl,
        storeCurrencyUsed: '',
        storeChargeId: 0,
        isArchived: true,
        createdAt: new Date().toISOString(),
        updatedAt: '',
        command: '',
      }),
    );

    console.log(store);

    localStorage.setItem('brandStoreId', store);
    onChangeStage(Stage.Plans);
  };

  const { dirty, isValid } = form;

  return (
    <Box component="div">
      <Typography component="div" variant="h3">
        Website Details
      </Typography>

      <FormikProvider value={form}>
        <Box component="form" noValidate onSubmit={handleSubmit} style={{ marginTop: '1.2rem' }}>
          <Box>
            <TextField
              id="outlined-helperText"
              value={valueUrl}
              onChange={onchangeUrl}
              label="Website Url"
              helperText=""
            />
          </Box>

          <Box marginTop="1.0rem">
            <TextField
              id="outlined-helperText"
              value={valueName}
              onChange={onchangeName}
              label="Display Name"
              helperText=""
            />
          </Box>
        </Box>
        <Box marginTop="8em">
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            sx={{ width: 'fit-content', float: 'right' }}
          >
            Next
          </Button>
        </Box>
      </FormikProvider>
    </Box>
  );
};
