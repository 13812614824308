import { createTheme } from '@mui/material/styles';

import backgroundImage from '../icons/site-background.webp';

const themeDetails = {
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          color: '#FFF',
          fontWeight: '600, Semi Bold',
          fontSize: '1rem',
          minWidth: '190px',
        },
        contained: {
          backgroundImage: 'linear-gradient(0deg, #E95E3B -148.44%, #FFB13D 123.44%)',
          color: '#FFF',
          border: '4px solid rgba(255, 255, 255, 0.29)',
          opacity: 1,
          padding: '1rem 2rem',
          borderRadius: '12px',
          boxShadow: 'none',
          ':disabled': {
            opacity: 0.6,
          },
          ':hover': {
            backgroundImage:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), linear-gradient(0deg, #E95E3B -148.44%, #FFB13D 123.44%)',
            boxShadow: '0px 4px 4px rgba(255, 177, 61, 0.48)',
          },
        },
        outlined: {
          color: '#0A2435',
          ':disabled': {
            color: '#0A2435',
            opacity: 0.6,
            border: '1px solid rgba(10, 36, 53, 0.5)',
          },
        },
        text: {
          color: '#0A2435',
        },
      },
    },

    FormControl: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        },
      },
    },

    // Input fileds override
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#f9f9f9',
          ':before': {
            borderBottom: '2px solid #eae9e9',
          },
        },
        underline: {
          ':hover': {
            ':before': {
              borderBottom: '2px solid #c8c8c8 !important',
            },
          },
          input: {},
        },
      },
      underline: {},
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: '#0A2435',
          textDecoration: 'none',
          fontWeight: '700',
          ':hover': {
            textDecoration: 'underline',
          },
          ':visited': {
            color: '#0A2435',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          verticalAlign: 'middle',
          // color: "#FFB13D",
        },
      },
    },
  },
  palette: {
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: '#fff' },
    action: {
      disabledBackgroundImage: 'linear-gradient(0deg, #E95E3B -148.44%, #FFB13D 123.44%)',
      disabled: '#FFF',
      backgroundImage: 'linear-gradient(0deg, #E95E3B -148.44%, #FFB13D 123.44%)',
    },
    primary: {
      main: '#FFB13D',
      contrastText: '#0A2435',
    },
    secondary: {
      main: 'rgba(10, 36, 53, 0.8)',
      contrastText: '#FFF',
    },
    error: {
      light: '#FF8185',
      main: '#EA6858',
      dark: '#C5483C',
      contrastText: '#FFF5F1',
    },
    text: {
      primary: '#333333',
      secondary: 'rgba(10, 36, 53, 0.6)',
      disabled: '#6F5955',
      hint: '#574142',
    },
  },
  typography: {
    fontFamily: ['Inter', 'system-ui', 'font-awesome'].join(','),
    h1: {
      letterSpacing: '0.01em',
    },
    h2: {
      letterSpacing: '0.01em',
    },
    h3: {
      letterSpacing: '0.01em',
    },
    h4: {
      letterSpacing: '0.01em',
    },
    h5: {
      letterSpacing: '0.01em',
    },
    h6: {
      fontWeight: '600',
      fontSize: '1rem',
    },
    subtitle1: {
      letterSpacing: '2px',
    },
    subtitle2: {
      letterSpacing: '2px',
    },
    body1: {
      fontFamily: 'Inter',
    },
    body2: {
      fontFamily: 'Inter',
      letterSpacing: '0px',
    },
    caption: {
      fontFamily: 'Inter',
    },
    overline: {
      fontFamily: 'Inter',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          background: `url("${backgroundImage}")`,
          backgroundSize: '100%',
        },
      },
    },
  },
};

const theme = createTheme(themeDetails);

export default theme;
