export const TriggerPopupWindow = (url: string, windowTitle: string, handlePopupClosed: any) => {
  const windowWidth = 500;
  const windowHeight = 750;
  const windowPositionLeft = (window.screen.width - windowWidth) / 2;
  const windowPositionTop = (window.screen.height - windowHeight) / 4;

  const popupWindow: Window | null = window.open(
    url,
    windowTitle,
    `width=${windowWidth},height=${windowHeight},top=${windowPositionTop},left=${windowPositionLeft}`,
  ); // window object of the popup
  const timer: NodeJS.Timer = setInterval(evaluateOpenPopup, 500); // timer for popup

  // check every 500ms if the popup is closed

  function evaluateOpenPopup() {
    if (popupWindow && popupWindow.closed) {
      handlePopupClosed();
      clearInterval(timer);
    }
  }
};
