import { registrationStageSlice } from './slice';
import { registrationStageApi } from '../../../api/registration-stage';
import { RegistrationStageInfo } from '../../../types/account/account';
import { AppDispatch } from '../../store';

export const getRegistrationStage = (userId: string, onFailure: (str: string) => void) => async (dispatch: AppDispatch) => {
  dispatch(registrationStageSlice.actions.registrationStageProcess());

  try {
    const { data } = await registrationStageApi.getRegistrationStage(userId);
    
    if(data == null)
      onFailure('FGRSN')

    dispatch(registrationStageSlice.actions.registrationStageSuccess(data.stage));

    return data.stage;
  } catch (error) {
    dispatch(registrationStageSlice.actions.registrationStageFailed());
    onFailure('FGRS');
  }
};

export const updateRegistrationStage =
  (registrationStage: RegistrationStageInfo, onFailure: (str: string) => void) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await registrationStageApi.updateRegistrationStage(registrationStage);

      dispatch(getRegistrationStage(data.result, onFailure));

      return data.result;
    } catch (error) {
      onFailure('URS');
    }
  };
