import { AllBrandInfo } from 'types/company/company'

export interface ConnectedSystems {
  google: boolean;
  
  facebook: boolean;
  shopify: boolean;
  tiktok: boolean;
  snapchat: boolean;
  googlega4:boolean;
  
}

const systems = {
  'Google Analytics UA': 'google',
  Facebook: 'facebook',
  Shopify: 'shopify',
  TikTok: 'tiktok',
  Snapchat: 'snapchat',
  'Google Analytics 4':'googlega4'
 
} as const;

export const mapConnectedSystems = (brandData: AllBrandInfo[]): Record<string, ConnectedSystems> => {
  return brandData.reduce((acc, store) => {
    const keyName = systems[store.systemName];

    return { ...acc, [store.clientBrandStoreId]: { ...acc[store.clientBrandStoreId], [keyName]: true } };
  }, {} as Record<string, ConnectedSystems>);
};
