import { Typography, Box, Button, Grid, MenuItem, Select } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC } from 'react';

import { companySchema } from './helpers/companySchema';
import building from '../../../../assets/icons/building.svg';
import { AutocompleteField } from '../../../../components/autocomplete-field';
import { Field } from '../../../../components/field/Field';
import { COUNTRIES } from '../../../../constants/coutries';
import { INDUSTRIES } from '../../../../constants/industries';
import { CompanyRegistrationData } from '../../../../types/company/company';

export interface Props {
  onSubmit: any;
}

export const CompanyRegistrationForm: FC<Props> = ({ onSubmit }) => {
  const form = useFormik<CompanyRegistrationData>({
    initialValues: {
      userId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: '',
      industry: null,
      url: '',
      phoneCountryPrefix: '',
      phoneNumber: '',
      addressDetails: {
        country: null,
        city: '',
        address: '',
        postcode: '',
      },
    },
    validationSchema: companySchema,
    onSubmit: values => {
      onSubmit(values);
    },
  });

  const { isValid } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate>
        <Box component="div" sx={{ marginBottom: '2rem' }}>
          <Typography component="div" variant="h4" sx={{ marginBottom: '0.6rem' }}>
            Register your company
          </Typography>
          <Typography component="div">Please fill in your company details</Typography>
        </Box>

        <Grid container alignItems={'center'} spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Box>
              <Typography component="div">
                <Typography
                  component="div"
                  sx={{
                    color: '#0A2435',
                    fontWeight: '700',
                    display: 'flex',
                  }}
                >
                  <Typography component="span">
                    <img src={building} alt="building" />
                  </Typography>
                  &nbsp; Company details
                </Typography>
              </Typography>

              <Field
                sx={{ display: 'inline-flex', width: '100%' }}
                required
                name="name"
                label="Company name"
                autoFocus
              />
              <AutocompleteField
                name="industry"
                label="Industry"
                options={INDUSTRIES}
                getOptionLabel={option => option.name}
              />

              <Field sx={{ display: 'inline-flex', width: '100%' }} name="url" label="Url" />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'baseline',
                }}
              >
                <Field sx={{ display: 'inline-flex', width: '100%' }} name="phoneCountryPrefix" label="Prefix" />
                <Field sx={{ display: 'inline-flex', width: '100%' }} name="phoneNumber" label="Phone Number" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box>
              <Typography component="div">
                <Typography
                  component="div"
                  sx={{
                    color: '#0A2435',
                    fontWeight: '700',
                    display: 'flex',
                  }}
                >
                  <Typography component="span">
                    <img src={building} alt="building" />
                  </Typography>
                  &nbsp; Address
                </Typography>
              </Typography>
              <AutocompleteField
                name="addressDetails.country"
                label="Country"
                options={COUNTRIES}
                getOptionLabel={option => option.name}
              />

              <Field sx={{ display: 'inline-flex', width: '100%' }} required name="addressDetails.city" label="City" />
              <Field
                sx={{ display: 'inline-flex', width: '100%' }}
                required
                name="addressDetails.address"
                label="Address"
              />
              <Field
                sx={{ display: 'inline-flex', width: '100%' }}
                required
                name="addressDetails.postcode"
                label="Postcode"
              />
            </Box>
          </Grid>
        </Grid>
        <Box marginTop="1.5rem">
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!isValid}
            sx={{ width: 'fit-content', float: 'right' }}
          >
            Next
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};
