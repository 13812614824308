import { CircularProgress, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { AxiosError } from 'axios';
import { ShopifyInit } from 'features/helpers/init-shopify-sdk';
import { useToast } from 'hooks/use-toast';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { getUser } from 'store/entities/account';
import { accountSelectors } from 'store/entities/account/selectors';
import { ShopLinkFormData } from 'types/shopify/shopify';

import classes from './CompanyRegistration.module.css';
import { ConnectorsLink } from './components/ConnectorsLink';
import { CompanyRegistrationForm } from './components/RegistrationForm';
import { ShopLink } from './components/ShopLink';
import { AccountLink } from './components/AccountLink';

import { PlanSelection } from '../../../components/plan-selection/PlanSelection';
import { addBrand, companyRegister, createClientMaper, setPlan } from '../../../store/entities/company/actions';
import { registrationStageSelectors } from '../../../store/entities/registration-stage';
import { getRegistrationStage, updateRegistrationStage } from '../../../store/entities/registration-stage/actions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Stage, UserFromToken } from '../../../types/account/account';
import { CompanyRegistrationData, Plan } from '../../../types/company/company';
import { Navigate, useNavigate } from 'react-router-dom';
import { RoutePaths } from 'containers/RoutePaths';
import { createBilling } from 'store/entities/shopify/actions';
import { companyAnalyticSelectors } from 'store/entities/companyAnalytics';
import { getAllBrandInfo } from 'store/entities/companyAnalytics/actions';
import { WebsiteDetail } from './components/WebsiteDetail';
import { ShopifyPrivate } from './components/ShopifyPrivate';

export const CompanyRegistration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { errorToast } = useToast();

  const stageLoading = useAppSelector(registrationStageSelectors.loading);
  const userLoading = useAppSelector(accountSelectors.loading);

  const stage = useAppSelector(registrationStageSelectors.stage);
  const userId = useAppSelector(accountSelectors.userId);
  const clientId = useAppSelector(accountSelectors.clientId);
  const brandId = useAppSelector(accountSelectors.brandId);
  const brandStoreId = useAppSelector(accountSelectors.brandStoreId);
  const connectedSystems = useAppSelector(companyAnalyticSelectors.connectedSystems);
  const storeName = useAppSelector(accountSelectors.storeName);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    //alert(stage);
    const init = async () => {
      try {
        const { UserId }: UserFromToken = jwtDecode(accessToken as string);

        const user = await dispatch(getUser(UserId));

        dispatch(getRegistrationStage(UserId, errorToast));
        if (user) {
          if (user.brandList !== null) {
            dispatch(getAllBrandInfo(user.brandList[0]?.Id, errorToast));
          }
        }
      } catch (e) {
        // errorToast();
      }
    };

    init();
  }, []);

  const onChangeStage = (stage: Stage) => {
    dispatch(updateRegistrationStage({ stage, userId: userId as string }, errorToast));
  };

  const onFormSubmit = async (values: CompanyRegistrationData) => {
    const Ismarketting = localStorage.getItem('marketingonly');
    const IsShopifyRegis = localStorage.getItem('isShopifyRegistration');
    try {
      const clientId = await dispatch(
        companyRegister({
          externalId: userId,
          parentExternalId: userId,
          companyName: values.name,
          industry: values.industry.id,
          url: values.url,
          countryPrefix: values.phoneCountryPrefix,
          contactNumber: values.phoneNumber,
          contactName: 'string',
          addressLine1: values.addressDetails.address,
          county: '',
          postcode: values.addressDetails.postcode,
          country: values.addressDetails.country.code,
          city: values.addressDetails.city,
          isNonRetail: Ismarketting == '1' ? true : false,
          isShopifyReg: IsShopifyRegis == 'true' ? true : false,
        }),
      );

      const brandId = await dispatch(addBrand(clientId, values.name));

      localStorage.setItem('clientId', clientId);
      localStorage.setItem('clientBrandId', brandId);

      await dispatch(
        createClientMaper({
          externalId: '00000000-0000-0000-0000-000000000000',
          clientId,
          userId,
        }),
      );

      if (Ismarketting == '1') {
        onChangeStage(Stage.WebsiteDetail);
      } else {
        onChangeStage(Stage.Shop);
      }

      await dispatch(getUser(userId));
    } catch (error) {
      const message = error instanceof AxiosError ? error?.response?.data.error_description : 'SWWCR001';

      errorToast(message);
    }
  };

  function cleanseStoreName(url: string): string {
    //remove http(s)
    if (url == null) return '';
    const str = url.replace(/^(https?:\/\/)?/, '');
    //split the rest of the string to only take the first part of the url if passed in
    return str.split('.')[0];
  }

  const createShop = (values: ShopLinkFormData) => {
    localStorage.setItem('storeName', values.shopName);
    localStorage.setItem('storeIdentifier', values.shopifyStoreLink);
    ShopifyInit(values.shopifyStoreLink, process.env.REACT_APP_SHOPIFY_REDIRECT_URL as string, dispatch, errorToast);
  };
  const returntoconnector = (values: ShopLinkFormData) => {
    onChangeStage(Stage.Plans);
  };

  const onPlanSelected = async (plan: Plan) => {
    try {
      await dispatch(setPlan(userId, clientId, plan.Id, errorToast));

      const connectionUrl = (await dispatch(
        createBilling({
          clientId,
          brandId,
          brandStoreId,
          shop: storeName,
          billingName: plan.title,
          price: plan.price,
        }),
      )) as string;
      const storeIdentifier = cleanseStoreName(localStorage.getItem('storeIdentifier'));
      const storeId = localStorage.getItem('brandStoreId');
      window.location.assign(connectionUrl);
      onChangeStage(Stage.BillingResult);
    } catch (error) {
      const message = error instanceof AxiosError ? error?.response?.data.error_description : 'Please select a plan.';

      errorToast(message);
    }
  };

  const render = () => {
    switch (stage) {
      case Stage.AccountType: {
        return <AccountLink />;
      }

      case Stage.Company: {
        return <CompanyRegistrationForm onSubmit={onFormSubmit} />;
      }

      case Stage.WebsiteDetail: {
        return <WebsiteDetail />;
      }
      // case Stage.Shop: {
      //   return <ShopifyPrivate onSubmit={returntoconnector} />;
      // }
      case Stage.Plans: {
        return <PlanSelection shopify classes={classes} onSubmit={onPlanSelected} />;
      }
      case Stage.BillingResult: {
        navigate(`../../${RoutePaths.BillingResult}`);
        break;
      }
      case Stage.Connectors: {
        return <ConnectorsLink connectedSystems={connectedSystems[brandStoreId]} classes={classes} />;
      }
      case Stage.Shop: {
        return <ShopLink onSubmit={createShop} />;
      }
      default:
        //default location if you try to navigate to an invalid endpoint
        navigate(`../../Account/${RoutePaths.SignUp}`);
        break;
    }
  };

  return (
    <Box className={classes.ContainerBox}>
      {stageLoading || userLoading ? (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        render()
      )}
    </Box>
  );
};
