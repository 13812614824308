import { LoadingButton } from '@mui/lab';
import { Typography, Box, Button } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useToast } from 'hooks/use-toast';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import classes from './ForgotPassword.module.css';
import { ForgotPasswordData } from './ForgotPasswordData';
import theme from '../../../assets/theme/theme';
import { Field } from '../../../components/field/Field';
import { forgotPassword } from '../../../store/entities/account/actions';
import { accountSelectors } from '../../../store/entities/account/selectors';
import { useAppSelector } from '../../../store/hooks';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { successToast, errorToast } = useToast();

  const loading = useAppSelector(accountSelectors.loading);

  const validationSchema = yup.object({
    email: yup.string().required('Email is required'),
  });

  const form = useFormik<ForgotPasswordData>({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values: ForgotPasswordData) => {
      try {
        await dispatch(forgotPassword(values.email));

        successToast('Email Sent');
      } catch (error) {
        errorToast();
      }
    },
  });

  const { isValid, dirty } = form;

  return (
    <Box className={classes.ContainerBox}>
      <Typography component="div" color={theme.palette.text.secondary} sx={{ marginBottom: '1.9rem' }}>
        Welcome to Vennflow
      </Typography>
      <Typography component="div" variant="h3">
        Forgotten Password?
      </Typography>
      <Typography component="div">
        No problems. Enter your email below and we will send you a password reset link
      </Typography>
      <FormikProvider value={form}>
        <Form noValidate>
          <Box sx={{ mt: 1 }}>
            <Field required name="email" label="Email" autoFocus />
            <Typography component="div">Please check your spam folder if the email is not in your inbox</Typography>
            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              type="submit"
              sx={{ marginTop: '1.5rem' }}
              disabled={!dirty || !isValid}
            >
              Request Reset Link
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
};
