import { Card, CardContent, styled } from '@mui/material';

const ConnectorsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 18px 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(10, 36, 53, 0.06) !important;
`;

const ConnectorsIcon = styled('img')`
  width: 56px;
  height: 56px;
`;

const ConnectorsContent = styled(CardContent)`
  min-height: '10rem';
  background: rgba(129, 129, 129, 0.06);
  border-top: 6px solid #f09418;
`;

const PlanIcon = styled('img')`
  width: 20px;
  height: 20px;
`;

const PlanCard = styled(Card)(({ selected }: { selected: boolean }) => ({
  border: selected ? '2px dashed #f09418' : 'none',
  borderTop: !selected ? '6px solid #f09418' : '2px dashed #f09418',
  height: '100%',
  padding: '1rem 0',
  background: 'rgba(129, 129, 129, 0.06)',
  scale: selected ? '105%' : '100%',
}));

export const settingsStyles = {
  ConnectorsCard,
  ConnectorsIcon,
  ConnectorsContent,
  PlanIcon,
  PlanCard,
};
