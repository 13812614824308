import { CircularProgress, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { AxiosError } from 'axios';
import { useToast } from 'hooks/use-toast';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { RegistrationForm } from './components/registration-form';
import { parseShopName } from './helpers/parse-shop-name';
import classes from './shopify-registration.module.css';
import { CodeValidation } from '../../../components/code-validation/CodeValidation';
import { PlanSelection } from '../../../components/plan-selection/PlanSelection';
import { RoutePaths } from '../../../containers/RoutePaths';
import { accountSlice } from '../../../store/entities/account';
import { addUser, getCompany, getUser, verifyCode } from '../../../store/entities/account/actions';
import { accountSelectors } from '../../../store/entities/account/selectors';
import { companyRegister, companySelectors, createClientMaper } from '../../../store/entities/company';
import { addBrand, setPlan } from '../../../store/entities/company/actions';
import { registrationStageSelectors } from '../../../store/entities/registration-stage';
import { updateRegistrationStage } from '../../../store/entities/registration-stage/actions';
import { shopifySelectors } from '../../../store/entities/shopify';
import { addClientBrandStore, createBilling, exchangeAccessCode } from '../../../store/entities/shopify/actions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Stage, UserFromToken } from '../../../types/account/account';
import { Plan } from '../../../types/company/company';
import { RegistrationFormData } from '../../../types/shopify/shopify';
import { ShopifyInit } from '../../helpers/init-shopify-sdk';

export const ShopifyRegistration = () => {
  const dispatch = useAppDispatch();

  const { successToast, errorToast } = useToast();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const stageLoading = useAppSelector(registrationStageSelectors.loading);
  const accountDataLoading = useAppSelector(accountSelectors.loading);
  const companyRegisterLoading = useAppSelector(companySelectors.companyRegisterLoading);
  const shopifyLoading = useAppSelector(shopifySelectors.loading);
  const stage = useAppSelector(registrationStageSelectors.stage);
  const userId = useAppSelector(accountSelectors.userId);

  const systemId = '17213426-046B-4C11-A1D3-4AD25E3C1273';

  const clientId = useAppSelector(accountSelectors.clientId);
  const brandId = useAppSelector(accountSelectors.brandId);
  const brandStoreId = useAppSelector(accountSelectors.brandStoreId);
  const storeName = useAppSelector(accountSelectors.storeName);

  function cleanseStoreName(url: string): string {
    //remove http(s)
    const str = url.replace(/^(https?:\/\/)?/, '');
    //split the rest of the string to only take the first part of the url if passed in
    return str.split('.')[0];
  }

  const changeStage = async (stage: Stage, userId: string) => {
    dispatch(updateRegistrationStage({ stage, userId }, errorToast));
  };

  const onFormSubmit = async (values: RegistrationFormData) => {
    try {
      const company = await dispatch(getCompany(values.companyName));
      localStorage.setItem('isShopifyRegistration', 'true');
      localStorage.setItem('marketingonly', '0');

      if (company == 1) {
        errorToast('Company already exist');
        dispatch(accountSlice.actions.failed());
      } else {
        const userId = await dispatch(
          addUser({
            contactEmail: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            isShopifyRegistration: true,
          }),
        );

        const clientId = await dispatch(
          companyRegister({
            externalId: userId,
            parentExternalId: userId,
            companyName: values.companyName,
            industry: '',
            url: '',
            countryPrefix: '',
            contactNumber: '',
            contactName: '',
            addressLine1: '',
            county: '',
            postcode: '',
            country: '',
            city: '',
            isNonRetail: false,
            isShopifyReg:true
          }),
        );

        await dispatch(
          createClientMaper({
            externalId: '00000000-0000-0000-0000-000000000000',
            clientId,
            userId,
          }),
        );

        await dispatch(addBrand(clientId, values.companyName));

        changeStage(Stage.ShopifyVerifyCode, userId);
      }
    } catch (error) {
      const message = error instanceof AxiosError ? error?.response?.data.error_description : 'SWWSR001';

      errorToast(message);
    }
  };

  const onVerifyCodeSubmitted = async (code: string) => {
    try {
      await dispatch(verifyCode(userId, code));

      changeStage(Stage.ShopifyBilling, userId);
    } catch (error) {
      const message = error instanceof AxiosError ? error?.response?.data.error_description : 'SWWSR002';

      errorToast(message);
    }
  };

  const onPlanSelected = async (plan: Plan) => {
    try {
      await dispatch(setPlan(userId, clientId, plan.Id, errorToast));

      const connectionUrl = (await dispatch(
        createBilling({
          clientId,
          brandId,
          brandStoreId,
          shop: storeName,
          billingName: plan.title,
          price: plan.price,
        }),
      )) as string;

      window.location.assign(connectionUrl);
      changeStage(Stage.BillingResult, userId);
    } catch (error) {
      const message = error instanceof AxiosError ? error?.response?.data.error_description : 'Please select a plan.';

      errorToast(message);
    }
  };

  const render = () => {
    switch (stage) {
      case Stage.ShopifyVerifyCode: {
        return <CodeValidation loading={accountDataLoading || shopifyLoading} onSubmit={onVerifyCodeSubmitted} />;
      }
      case Stage.ShopifyBilling: {
        return <PlanSelection shopify classes={classes} onSubmit={onPlanSelected} />;
      }
      case Stage.BillingResult: {
        navigate(`../../${RoutePaths.BillingResult}`);
        break;
      }
      default: {
        return (
          <RegistrationForm
            onSubmit={onFormSubmit}
            loading={accountDataLoading || companyRegisterLoading || stageLoading}
            classes={classes}
          />
        );
      }
    }
  };

  useEffect(() => {
    if (searchParams.get('shop') && !searchParams.get('code')) {
      const shopName = cleanseStoreName(parseShopName(searchParams.get('shop') as string));
      localStorage.removeItem('accessToken');
      localStorage.removeItem('storeIdentifier');

      localStorage.setItem('storeName', shopName);

      ShopifyInit(shopName, process.env.REACT_APP_SHOPIFY_REGISTRATION_REDIRECT_URL as string, dispatch, errorToast);
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      const token = localStorage.getItem('accessToken');

      try {
        let userResponse;
        let userId;

        if (token) {
          const { UserId }: UserFromToken = jwtDecode(token);

          userId = UserId;
          userResponse = await dispatch(getUser(UserId));
        }

        const storeName = localStorage.getItem('storeName');

        if (searchParams.get('code') && stage === Stage.ShopifyBilling && storeName && userResponse) {
          const { clientId, brandList } = userResponse;
          const code = searchParams.get('code') as string;
          const brandId = brandList[0].Id;

          const brandStoreId = await dispatch(
            addClientBrandStore({
              clientId,
              clientBrandId: brandId,
              storeName: storeName,
              storeIdentifier: storeName,
              systemId,
              storeDescription: '',
              storeCurrencyUsed: 'GBP',
            }),
          );

          await dispatch(
            exchangeAccessCode(
              {
                clientId,
                clientBrandId: brandId,
                clientBrandStoreId: brandStoreId,
                shopId: storeName,
              },
              code,
            ),
          );

          await dispatch(getUser(userId));

          localStorage.removeItem('storeName');
        }
      } catch {
        errorToast();
      }
    };
    init();
  }, [stage]);

  return (
    <Box className={classes.ContainerBox}>
      {stageLoading || shopifyLoading ? (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        render()
      )}
    </Box>
  );
};
