import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC } from 'react';

interface Props {
  value: boolean;
  onClick: () => void;
}

export const VisibleButton: FC<Props> = ({ value, onClick }) => (
  <IconButton size="small" sx={{ marginRight: '2px' }} onClick={onClick} edge="end">
    {value ? <VisibilityOff sx={{ fontSize: '20px' }} /> : <Visibility sx={{ fontSize: '20px' }} />}
  </IconButton>
);
