import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'types/account/account';

import { AccountSlice } from './types';

const initialState: AccountSlice = {
  loading: false,
  user: {
    brandList: [
      {
        Id: '',
        Name: '',
      },
    ],
    brandStoreList: [
      {
        Id: '',
        Name: '',
      },
    ],
    externalUserId: '',
    clientId: '',
    firstName: '',
    lastName: '',
    contactEmail: '',
    contactNumber: '',
    companyPosition: '',
    addressline1: '',
    addressline2: '',
    postcode: '',
    county: '',
    country: '',
    allowMarketing: false,
    isNonRetail:false,
    isShopifyReg: false,
  },
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.user.contactEmail = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.user.externalUserId = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    process: state => {
      state.loading = true;
    },
    success: state => {
      state.loading = false;
    },
    failed: state => {
      state.loading = false;
    },
  },
});
