import { Response } from '../../types/api/api';
import {
  ExchangeAccessCodeRequest,
  CreateBillingRequest,
  BillingResultRequest,
  BillingResult,
  AddClientBrandStoreRequest,
  VerifyATScopeAccessRequest,
  ShopifyPrivateAccessSetupRequest,
} from '../../types/shopify/shopify';
import { baseApi } from '../api';

const getInstallLink = (storeName: string) =>
  baseApi.get<Response<{ url: string }>>(`ShopifyConnector/AuthUrl/${storeName}`);

const exchangeAccessCode = (params: ExchangeAccessCodeRequest, code: string) =>
  baseApi.get<Response<string>>(`shopifyConnector/exchangeAccessCodeForAccessToken/${code}`, { params });

const addClientBrandStore = (data: AddClientBrandStoreRequest) =>
  baseApi.post<Response<string>>('Shops/ClientBrandStore', data);

const ShopifyPrivateAccessSetup = (data: ShopifyPrivateAccessSetupRequest) =>
  baseApi.post<Response<string>>('ShopifyConnector/ShopifyPrivateAccessSetup', data);

const createBilling = (data: CreateBillingRequest) =>
  baseApi.post<Response<{ connectionUrl: string }>>('ShopifyConnector/CreateBilling', data);

const verifyATScopeAccess = (data: VerifyATScopeAccessRequest) =>
  baseApi.post('ShopifyConnector/VerifyATScopeAccess', data);

const billingResult = (data: BillingResultRequest) =>
  baseApi.post<Response<BillingResult>>('ShopifyConnector/BillingResult', data);

export const shopifyApi = {
  getInstallLink,
  exchangeAccessCode,
  createBilling,
  billingResult,
  addClientBrandStore,
  verifyATScopeAccess,
  ShopifyPrivateAccessSetup,
};
