import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useToast } from 'hooks/use-toast';
import jwtDecode from 'jwt-decode';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUser } from 'store/entities/account';
import { accountSelectors } from 'store/entities/account/selectors';

import { RoutePaths } from '../../containers/RoutePaths';
import { registrationStageSelectors } from '../../store/entities/registration-stage';
import { updateRegistrationStage } from '../../store/entities/registration-stage/actions';
import { shopifySelectors } from '../../store/entities/shopify';
import { billingResult } from '../../store/entities/shopify/actions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Stage, UserFromToken } from '../../types/account/account';
import { BillingStatus } from '../../types/shopify/shopify';
import axios from 'axios';
import { getStatus } from 'store/entities/stripe';

export const BillingResult: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const [searchParams] = useSearchParams();

  const charge_id = searchParams.get('charge_id') as string;

  const billingLoading = useAppSelector(shopifySelectors.loading);
  const stageLoading = useAppSelector(registrationStageSelectors.loading);
  const userLoading = useAppSelector(accountSelectors.loading);
  const clientId = useAppSelector(accountSelectors.clientId);

  const userId = useAppSelector(accountSelectors.userId);

  const [status, setStatus] = useState<BillingStatus>('pending');

  function cleanseStoreName(url: string): string {
    //remove http(s)
    const str = url.replace(/^(https?:\/\/)?/, '');
    //split the rest of the string to only take the first part of the url if passed in
    return str.split('.')[0];
  }
  //build test
  let storeIdentifier = localStorage.getItem('storeIdentifier');
  //if there is something that may need cleansing then cleanse it

  if (storeIdentifier !== '' && storeIdentifier !== null) {
    storeIdentifier = cleanseStoreName(localStorage.getItem('storeIdentifier'));
  }

  const isShopifyRegistration = localStorage.getItem('isShopifyRegistration') === 'true';

  const navigateToConnectors = () => {
    navigate(`/${RoutePaths.Company}/${RoutePaths.CompanyRegistration}`);
  };

  const navigateToPlans = () => {
    if (!isShopifyRegistration) {
      dispatch(updateRegistrationStage({ stage: Stage.Plans, userId }, errorToast));

      navigate(`/${RoutePaths.Company}/${RoutePaths.CompanyRegistration}`);
    } else {
      dispatch(updateRegistrationStage({ stage: Stage.ShopifyBilling, userId }, errorToast));

      navigate(`/${RoutePaths.Shopify}/${RoutePaths.ShopifyRegistration}`);
    }
  };

  const changeStage = async (stage: Stage, userId: string) => {
    dispatch(updateRegistrationStage({ stage, userId }, errorToast));
  };

  useEffect(() => {
    const init = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const { UserId }: UserFromToken = jwtDecode(token);

        const { clientId, brandList, brandStoreList } = await dispatch(getUser(UserId));

        if (isShopifyRegistration) {
          const brandId = brandList[0].Id;
          const { Id: brandStoreId, Name: storeName } = brandStoreList[0];

          const result = charge_id
            ? await dispatch(
                billingResult({
                  shop: isShopifyRegistration ? storeName : storeIdentifier,
                  clientId,
                  brandId,
                  brandStoreId,
                  charge_id,
                }),
              )
            : null;

          setStatus(result ? result.status : 'pending');

          if (charge_id == null) {
            changeStage(Stage.ShopifyBilling, userId);
            navigate(`/${RoutePaths.Shopify}/${RoutePaths.ShopifyRegistration}`, { replace: true });
          }

          if (result && result.status === 'active') {
            dispatch(updateRegistrationStage({ stage: Stage.Connectors, userId: UserId }, errorToast));
          }
        } else {
          const request = { clientId: '' };
          request.clientId = clientId;
          const stripeStatus: any = await dispatch(getStatus(request));
          if (stripeStatus.result.success) {
            if (stripeStatus.data.result.status == 'paid') {
              setStatus('active');
            } else {
              setStatus('pending');
            }
          }
        }
      } catch (error) {
        setStatus('declined');
        errorToast();
      }
    };
    init();
  }, []);

  return billingLoading || stageLoading || userLoading || status === 'pending' ? (
    <Grid item xs={12}>
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    </Grid>
  ) : status === 'active' ? (
    <Box
      height="80vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Typography mb="1rem" variant="h3">
        Everything all right!
      </Typography>
      <Typography variant="h5" mb="1rem">
        Now it’s time to link your other data sources to Vennflow!
      </Typography>

      <Button onClick={navigateToConnectors} variant="contained">
        Continue
      </Button>
    </Box>
  ) : (
    <Box
      textAlign="center"
      height="80vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography mb="1rem" variant="h3">
        Billing didn`t complete successfully
      </Typography>
      <Typography variant="h5" mb="1rem">
        {`Status: ${status}`}
      </Typography>
      <Button onClick={navigateToPlans} variant="contained">
        Try again
      </Button>
    </Box>
  );
};
