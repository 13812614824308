import * as yup from 'yup';

import { passwordSchema } from '../../../utils/password-schema';

export const validationSchema = yup.object({
  contactEmail: yup.string().email('Enter a valid email').required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  companyName:yup.string().required('Company name is required'),
  password: passwordSchema,
  //tandc: yup.string().required('Terms and Conditions must be accepted').oneOf([''], 'Terms and Conditions must be accepted')
});
