import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const GoogleRedirectGA4 = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const init = async () => {
      localStorage.setItem('ga4code', searchParams.get('code') || '');

      window.close();
    };

    init();
  }, []);

  return <>Redirecting...</>;
};
