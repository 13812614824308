import { accountSlice } from './slice';
import { accountApi } from '../../../api/account';
import { AddUserRequest, LoginRequest, ResetPasswordRequest } from '../../../types/account/account';
import { AppDispatch } from '../../store';
import { companyApi } from 'api/company';
import { Company } from 'types/company/company';

export const login = (userData: LoginRequest) => async (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.process());

  try {
    const { data } = await accountApi.login(userData);

    dispatch(accountSlice.actions.success());

    return data.result;
  } catch (error) {
    dispatch(accountSlice.actions.failed());

    throw error;
  }
};

export const addUser = (user: AddUserRequest) => async (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.process());

  try {
    const { data } = await accountApi.addUser(user);

    const userId = data.result;

    dispatch(accountSlice.actions.setUserId(userId));
    dispatch(accountSlice.actions.setEmail(user.contactEmail));
    dispatch(accountSlice.actions.success());

    return userId;
  } catch (error) {
    dispatch(accountSlice.actions.failed());

    throw error;
  }
};

export const getUser = (userId: string) => async (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.process());

  try {
    const { data: user } = await accountApi.getUser(userId);

    dispatch(accountSlice.actions.setUser(user));
    dispatch(accountSlice.actions.success());

    return user;
  } catch (error) {
    dispatch(accountSlice.actions.failed());

    throw error;
  }
};

export const getCompanyName = (userId: string) => async (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.process());

  try {
    const { data: user } = await accountApi.getUser(userId);

    dispatch(accountSlice.actions.setUser(user));
    dispatch(accountSlice.actions.success());

    return user;
  } catch (error) {
    dispatch(accountSlice.actions.failed());

    throw error;
  }
};

export const verifyCode = (externalUserId: string, code: string) => async (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.process());
  try {
    const { data } = await accountApi.verifyCode(externalUserId, code);
    dispatch(accountSlice.actions.success());

    localStorage.setItem('accessToken', data.result);

    return data.result;
  } catch (error) {
    dispatch(accountSlice.actions.failed());

    throw error;
  }
};

export const resetPassword = (params: ResetPasswordRequest) => async (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.process());

  try {
    await accountApi.resetPassword(params);

    dispatch(accountSlice.actions.success());
  } catch (error) {
    dispatch(accountSlice.actions.failed());

    throw error;
  }
};

export const forgotPassword = (email: string) => async (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.process());

  try {
    await accountApi.forgotPassword(email);

    dispatch(accountSlice.actions.success());
  } catch (error) {
    dispatch(accountSlice.actions.failed());

    throw error;
  }
};

export const getCompany = (company: string) => async (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.process());

  try {
    const { data: user } = await companyApi.getCompany(company);
    return 1; // exist
  } catch (error) {
    // if not exist
    return -1;
  }
};

export const getCompanybyClient = (clientId: string , onSuccess: () => void, onFailure: () => void) => async (dispatch: AppDispatch) => {

  try {
    const { data } = await companyApi.getCompanybyClient(clientId);
    onSuccess();

    return data; // exist
  } catch (error) {
    // if not exist
    onFailure();

    return null;
  }
};

//getCompanybyClient

export const updateCompany =
  (params: Company, onSuccess: () => void, onFailure: () => void) =>
  async (dispatch: AppDispatch) => {

    try {
    const {data}=  await companyApi.updateCompany(params);
    onSuccess();

      return data;
    } catch (error) {
      onFailure();
    }
  };
