import { LoadingButton } from '@mui/lab';
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  TextField,
  Link,
  IconButton,
  Checkbox,
  styled,
  TooltipProps,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { shopifySelectors } from 'store/entities/shopify';
import * as yup from 'yup';
import theme from '../../../../assets/theme/theme';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ShopLinkFormData } from '../../../../types/shopify/shopify';
import { Favorite, FavoriteBorder } from '@mui/icons-material';

import AnnouncementIcon from '@mui/icons-material/Announcement';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { AccountTypeCard } from './AccountTypeCard';
import { getUser } from 'store/entities/account';
import { updateRegistrationStage } from 'store/entities/registration-stage/actions';
import { Stage } from 'types/account/account';
import { accountSelectors } from 'store/entities/account/selectors';
import { useToast } from 'hooks/use-toast';
import React from 'react';
import { getCompanybyClient, updateCompany } from 'store/entities/account/actions';
import { Company } from 'types/company/company';

export const AccountLink = () => {
  const loading = useAppSelector(shopifySelectors.loading);
  const dispatch = useAppDispatch();
  const userId = useAppSelector(accountSelectors.userId);
  const { errorToast } = useToast();
  const shopifyRegEnabled: boolean = process.env.REACT_APP_ENABLE_SHOPIFY_REG === 'true' ? true : false;
  const marketingRegEnabled: boolean = process.env.REACT_APP_ENABLE_MARKETINGONLY_REG === 'true' ? true : false;
  const promotionCodeEnabled: boolean = process.env.REACT_APP_ENABLE_PROMOTION_CODE === 'true' ? true : false;

  const expectedPromoCode = process.env.REACT_APP_PROMOTION_CODE;

  let promoCode: string = '';
  const onChangePromoCode = (event: any) => {
    promoCode = event.target.value;
  };

  const selectAdvanced = async () => {
    if (promotionCodeEnabled && promoCode !== expectedPromoCode) {
      errorToast('Promotion code not valid, cannot proceed!');
      return;
    }

    //getCompanybyClient
    const { clientId, brandList } = await dispatch(getUser(userId));

    const currentDate: string = new Date().toISOString();
    try {
      const update = {
        Id: clientId, //'F090EE17-B6E3-4BD5-90BE-2B3E05D3E05F',
        companyName: brandList[0].Name, // 'JC88FJFJ',
        contactName: 'test',
        isNonRetail: false,
        isShopifyReg: true,
        allowMarketing: false,
        updatedAt: currentDate, //'2024-01-25T11:42:37.601Z',
      };

      const data = await dispatch(updateCompany(update, () => console.log('Info updated'), errorToast));
      if (data.result) {
        localStorage.setItem('marketingonly', '0');
        localStorage.setItem('isShopifyRegistration', 'true');
        onChangeStage(Stage.Shop);
      } else {
        errorToast('Cannot update company detail!');
      }
    } catch {
      errorToast('Cannot update company detail!');
    }

    // dispatch(getUser(userId));
  };

  const SelectBasic = async () => {
    if (promotionCodeEnabled && promoCode !== expectedPromoCode) {
      errorToast('Promotion code not valid, cannot proceed!');
      return;
    }

    localStorage.setItem('marketingonly', '1');
    localStorage.setItem('isShopifyRegistration', 'false');

    const { clientId, brandList } = await dispatch(getUser(userId));

    const currentDate: string = new Date().toISOString();
    try {
      const update = {
        Id: clientId, //'F090EE17-B6E3-4BD5-90BE-2B3E05D3E05F',
        companyName: brandList[0].Name, // 'JC88FJFJ',
        contactName: 'test',
        isNonRetail: true,
        allowMarketing: true,
        updatedAt: currentDate, //'2024-01-25T11:42:37.601Z',
      };

      const data = await dispatch(updateCompany(update, () => console.log('Info updated'), errorToast));
      if (data.result) {
        //    onChangeStage(Stage.Company);
        onChangeStage(Stage.WebsiteDetail);
      } else {
        errorToast('Cannot update company detail!');
      }
    } catch {
      errorToast('Cannot update company detail!');
    }

    // dispatch(getUser(userId));
  };

  const onChangeStage = (stage: Stage) => {
    dispatch(updateRegistrationStage({ stage, userId: userId as string }, errorToast));
  };

  return (
    <Container maxWidth="xl">
      <Grid item xs={12} lg={12}>
        <Grid item xs={12} lg={12}>
          <Typography component="div" variant="h3">
            Account Type Selection
          </Typography>
          <br />
          <Typography component="div" variant="h6">
            Review the below options to understand what account is right for you!
          </Typography>
        </Grid>

        <br />

        <Grid container item direction="row" xs={12} lg={12}>
          <Grid item xs={1} lg={1} />
          {shopifyRegEnabled && (
            <AccountTypeCard
              accountTitle="For Shopify Owners"
              onClick={() => selectAdvanced()}
              accountTypeImage={require('../../../../assets/images/shopify-logo.svg').default}
              accountBlurb="Do you have a Shopify store, Google Analytics and use Digital Marketing channels? Then this is the account you want."
            />
          )}

          <Grid item xs={1} lg={1} />

          {marketingRegEnabled && (
            <AccountTypeCard
              accountTitle="Basic"
              onClick={() => SelectBasic()}
              accountTypeImage={require('../../../../assets/images/marketing.svg').default}
              accountBlurb="You use Google Analytics and Digital Marketing channels and want to understand how they are working for you? Then this is the account you want."
            />
          )}
        </Grid>
        <br />
        <hr />

        {promotionCodeEnabled && (
          <Grid>
            <br />
            <Typography variant="h6">Access Registration Code</Typography>
            <br />
            <Box>
              <TextField label="Access Code" id="promoCode" onChange={onChangePromoCode} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
