import { Autocomplete, TextField } from '@mui/material';
import { useField } from 'formik';

interface Props<T> {
  name: string;
  label: string;
  options: T[];
  sx?: object;
  getOptionLabel: (option: any) => string;
}

export const AutocompleteField = <T extends object>({ name, options, label, sx, getOptionLabel }: Props<T>) => {
  const [{ value }, { touched, error }, { setValue }] = useField({ name });

  return (
    <Autocomplete
      sx={sx}
      id={name}
      options={options}
      value={value ?? null}
      onChange={(event: any, newValue) => {
        setValue(newValue as object);
      }}
      getOptionLabel={getOptionLabel}
      renderInput={params => (
        <TextField
          {...params}
          margin="normal"
          variant="filled"
          fullWidth
          required
          error={touched && !!error}
          helperText={touched && error ? error : ' '}
          label={label}
          id={name}
        />
      )}
    />
  );
};
