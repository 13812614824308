import { LoadingButton } from '@mui/lab';
import { Typography, Box, Link } from '@mui/material';
import { AxiosError } from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { useToast } from 'hooks/use-toast';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import classes from './Login.module.css';
import { LoginData } from './LoginData';
import theme from '../../../assets/theme/theme';
import { Field } from '../../../components/field/Field';
import { PasswordField } from '../../../components/password-field';
import { RoutePaths } from '../../../containers/RoutePaths';
import { accountSlice, getUser, login } from '../../../store/entities/account';
import { accountSelectors } from '../../../store/entities/account/selectors';
import { getRegistrationStage } from '../../../store/entities/registration-stage';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { LoginResult, Stage, UserFromToken } from '../../../types/account/account';
import { useEffect } from 'react';

export const Login = () => {
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const navigate = useNavigate();

  const loading = useAppSelector(accountSelectors.loading);

  const onSubmit = async (values: LoginData) => {
    //TODO: send request to api
    localStorage.clear();
    try {
      const { access_token, refresh_token } = (await dispatch(
        login({
          username: values.Username,
          password: values.Password,
        }),
      )) as LoginResult;

      localStorage.setItem('accessToken', access_token);
      const { UserId }: UserFromToken = jwtDecode(access_token);
      const user = await dispatch(getUser(UserId));
      if (user) {
        if (!user.isNonRetail) {
          localStorage.setItem('marketingonly', '0');
        } else {
          localStorage.setItem('marketingonly', '1');
        }
        if (user.isShopifyReg) {
          localStorage.setItem('isShopifyRegistration', 'true');
        } 
      }

      dispatch(accountSlice.actions.setUserId(UserId));

      const stage = await dispatch(getRegistrationStage(UserId, errorToast));

      if (stage === Stage.Finished || !stage) {
        localStorage.clear();
        window.open(
          `${process.env.REACT_APP_REDIRECT_URL}?accessToken=${access_token}&refreshToken=${refresh_token}`,
          '_self',
        );
      } else if (stage === Stage.ShopifyVerifyCode || stage === Stage.ShopifyBilling || stage === Stage.BillingResult) {
        navigate(`/${RoutePaths.Shopify}/${RoutePaths.ShopifyRegistration}`, { replace: true });
      } else {
        navigate(`/${RoutePaths.Company}/${RoutePaths.CompanyRegistration}`, { replace: true });
      }
    } catch (error) {
      const message = error instanceof AxiosError ? error?.response?.data.error_description : 'SWWL001';

      errorToast(message);
    }
  };

  const validationSchema = yup.object({
    Username: yup.string().required('Username is required'),
    Password: yup.string().min(8, 'Password should be of minimum 8 characters length').required('Password is required'),
  });

  const form = useFormik<LoginData>({
    initialValues: {
      Username: '',
      Password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      setValues({
        Username: values.Username.trim(),
        Password: values.Password.trim(),
      });
      onSubmit({
        Username: values.Username.trim(),
        Password: values.Password.trim(),
      });
    },
  });

  // useEffect(() => {
  //   localStorage.clear();
  // });

  const { dirty, isValid, setValues, handleSubmit } = form;

  return (
    <Box className={classes.ContainerBox}>
      <Typography component="div" color={theme.palette.text.secondary} sx={{ marginBottom: '1.9rem' }}>
        Welcome to Vennflow
      </Typography>
      <Typography component="div" variant="h3">
        Log in
      </Typography>
      <FormikProvider value={form}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box className={classes.ContainerBox}>
            <Field required name="Username" label="Username" autoFocus />
            <PasswordField name="Password" label="Password" />
            <Link onClick={() => navigate(`/${RoutePaths.Account}/${RoutePaths.ForgotPassword}`)}>
              <Typography component="div" sx={{ marginLeft: '21rem' }}>
                Forgot Password?
              </Typography>
            </Link>
            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              type="submit"
              sx={{ marginTop: '1.5rem' }}
              disabled={!dirty || !isValid}
            >
              Continue
            </LoadingButton>
          </Box>
        </Box>
        <Box className={classes.LocalFooter}>
          <hr />
          Don't have an account? &nbsp;
          <Link onClick={() => navigate(`/${RoutePaths.Account}/${RoutePaths.SignUp}`)}>Sign up</Link>
        </Box>
      </FormikProvider>
    </Box>
  );
};
