import * as yup from 'yup';

export const companySchema = yup.object({
  name: yup.string().required('Company must have a name.'),
  industry: yup.object().nullable().required('Industry is required'),
  addressDetails: yup.object().shape({
    country: yup.object().nullable().required('Country is required'),
    city: yup.string().required('City is required'),
    address: yup.string().required('Address line is required'),
    postcode: yup.string().required('Postcode is required'),
  }),
});
