export const RoutePaths = {
  CookiePage: 'CookiePage',
  Counter: 'Counter',
  Account: 'Account',
  SignUp: 'SignUp',
  SignUpVerify: 'Verify',
  SignIn: 'SignIn',
  ForgotPassword: 'ForgotPassword',
  ResetPassword: 'ChangePassword',
  Company: 'Company',
  CompanyRegistration: 'Registration',
  Shopify: 'Shopify',
  ShopifyRegistration: 'Registration',
  BillingResult: 'ShopifyConnector/BillingResult',
  Finish: 'Finish',
  Install: 'Install',
  Connectors:'Connectors',
  MarketPlace: 'MarketPlace',


};
