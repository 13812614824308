import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { parseShopName } from '../../helpers/parse-shop-name';
import { ShopifyInit } from 'features/helpers/init-shopify-sdk';
import { Box } from '@mui/material';

import { useToast } from 'hooks/use-toast';

export const ShopifyInstall = () => {
  const { successToast, errorToast } = useToast();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  function cleanseStoreName(url: string): string {
    //remove http(s)
    const str = url.replace(/^(https?:\/\/)?/, '');
    //split the rest of the string to only take the first part of the url if passed in
    return str.split('.')[0];
  }
  useEffect(() => {
    if (searchParams.get('hmac') && searchParams.get('shop')) {
      const shopName = cleanseStoreName(parseShopName(searchParams.get('shop') as string));
      localStorage.removeItem('accessToken');
      localStorage.removeItem('storeIdentifier');
      localStorage.setItem('storeName', shopName);
      ShopifyInit(shopName, process.env.REACT_APP_SHOPIFY_REGISTRATION_REDIRECT_URL as string, dispatch, errorToast);

      //do redirect with permission url
    }
  }, []); 

  return <p hidden>Redirecting</p>;
};
