import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Outlet } from 'react-router-dom';

import classes from './MarketPlaceLayout.module.css';
import logo from '../../assets/images/logo.svg';
import sideImage from '../../assets/images/sideImage.svg';
import { Link } from '@mui/material';

export const MarketPlaceLayout = () => (
  <Grid container component="div" sx={{ height: '100vh' }}>
    <Grid item xs={12} sm={8} md={6} component={Paper} elevation={0} square>
      <Link href="https://www.vennflow.com">
        <img src={logo} className={classes.Logo} alt="logo" />
      </Link>
      <Grid container alignItems={'center'} className={classes.heightFix}>
        <Grid item className={classes.AccountVariant}>
          <Outlet />
        </Grid>
      </Grid>
    </Grid>
    <Grid
      item
      xs={false}
      sm={4}
      md={6}
      sx={{
        backgroundImage: `url("${sideImage}")`,
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  </Grid>
);
