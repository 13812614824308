import { shopifySlice } from './slice';
import { shopifyApi } from '../../../api/shopify';
import {
  ExchangeAccessCodeRequest,
  CreateBillingRequest,
  BillingResultRequest,
  AddClientBrandStoreRequest,
  VerifyATScopeAccessRequest,
  ShopifyPrivateAccessSetupRequest,
} from '../../../types/shopify/shopify';
import { AppDispatch } from '../../store';

function cleanseStoreName(url: string): string {
  //remove http(s)
  const str = url.replace(/^(https?:\/\/)?/, '');
  //split the rest of the string to only take the first part of the url if passed in
  return str.split('.')[0];
}

export const getInstallLink = (storeName: string) => async (dispatch: AppDispatch) => {
  dispatch(shopifySlice.actions.process());
  try {
    const cleanedStoreName = cleanseStoreName(storeName);

    const { data } = await shopifyApi.getInstallLink(cleanedStoreName);

    dispatch(shopifySlice.actions.success());

    return data.result.url;
  } catch (error) {
    dispatch(shopifySlice.actions.failed());
    throw error;
  }
};

export const exchangeAccessCode =
  (params: ExchangeAccessCodeRequest, code: string) => async (dispatch: AppDispatch) => {
    dispatch(shopifySlice.actions.process());
    try {
      await shopifyApi.exchangeAccessCode(params, code);

      dispatch(shopifySlice.actions.success());
    } catch (error) {
      dispatch(shopifySlice.actions.failed());
      throw error;
    }
  };

export const addClientBrandStore = (storeData: AddClientBrandStoreRequest) => async (dispatch: AppDispatch) => {
  dispatch(shopifySlice.actions.process());
  try {
    const { data } = await shopifyApi.addClientBrandStore(storeData);
    const brandStoreId = data.result;

    dispatch(shopifySlice.actions.success());

    return brandStoreId;
  } catch (error) {
    dispatch(shopifySlice.actions.failed());
    throw error;
  }
};


export const shopifyPrivateAccessSetup = (storeData: ShopifyPrivateAccessSetupRequest) => async (dispatch: AppDispatch) => {
  dispatch(shopifySlice.actions.process());
  try {
    const { data } = await shopifyApi.ShopifyPrivateAccessSetup(storeData);
    const brandStoreId = data.result;

    dispatch(shopifySlice.actions.success());

    return brandStoreId;
  } catch (error) {
    dispatch(shopifySlice.actions.failed());
    throw error;
  }
};


export const createBilling = (billingData: CreateBillingRequest) => async (dispatch: AppDispatch) => {
  dispatch(shopifySlice.actions.process());
  try {
    const { data } = await shopifyApi.createBilling(billingData);

    dispatch(shopifySlice.actions.success());

    return data.result.connectionUrl;
  } catch (error) {
    dispatch(shopifySlice.actions.failed());
    throw error;
  }
};

export const billingResult = (billingData: BillingResultRequest) => async (dispatch: AppDispatch) => {
  dispatch(shopifySlice.actions.process());
  try {
    const { data } = await shopifyApi.billingResult(billingData);

    dispatch(shopifySlice.actions.success());

    return data.result;
  } catch (error) {
    dispatch(shopifySlice.actions.failed());
    throw error;
  }
};

export const VerifyATScopeAccess = (ATScopeData: VerifyATScopeAccessRequest) => async (dispatch: AppDispatch) => {
  dispatch(shopifySlice.actions.process());
  try {
    const { data } = await shopifyApi.verifyATScopeAccess(ATScopeData);

    dispatch(shopifySlice.actions.success());

    return data.result;
  } catch (error) {
    dispatch(shopifySlice.actions.failed());
    throw error;
  }
};
