import { LoadingButton } from '@mui/lab';
import { Typography, Box, Link, Checkbox } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useToast } from 'hooks/use-toast';
import { FC, Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { validationSchema } from './helpers/sign-up-schema';
import { Field } from '../../../../components/field/Field';
import { PasswordField } from '../../../../components/password-field';
import { RoutePaths } from '../../../../containers/RoutePaths';
import { SignUpFormData } from '../../../../types/account/account';
import { Toast } from '@shopify/app-bridge/actions';

export interface Props {
  loading: boolean;
  classes: any;
  onSubmit: (values: SignUpFormData) => void;
}

export const SignUpForm: FC<Props> = ({ loading, classes, onSubmit }) => {
  const navigate = useNavigate();

  const form = useFormik<SignUpFormData>({
    initialValues: {
      firstName: '',
      lastName: '',
      contactEmail: '',
      companyName:'',
      password: ''
    },
    validationSchema,
    onSubmit: values => {
      if(tandcAccepted)
        onSubmit(values);
      else
        errorToast('Please accept the Terms and Conditions to register!');
    },
  });

  useEffect(() => {
    localStorage.clear();
  });

  const [tandcAccepted, setTandC] = useState(false);
  const { successToast, errorToast } = useToast();

  const { dirty, isValid, handleSubmit } = form;

  return (
    <Fragment>
      <Typography component="div" variant="h3" className={classes.heading}>
        Sign up
      </Typography>
      <FormikProvider value={form}>
        <Form noValidate>
          <Box className={classes.ContainerBox}>
            <Field required name="contactEmail" label="Email" autoFocus />
            <Field required name="firstName" label="First Name" />
            <Field required name="lastName" label="Last Name" />
            <Field fullWidth required name="companyName" label="Company Name" />
            <PasswordField name="password" label="password" />
            <Typography component="div" className={classes.PasswordTextHolder}>
              The password requires a minimum of 8 characters, with at least 1 uppercase letter, 1 lowercase letter and
              one symbol
            </Typography>
            <Box className={classes.TandcHolder}>
              <Typography component="div" className={classes.tandcfont}>
                <Checkbox required size="medium" onClick={() => { setTandC(!tandcAccepted);}} />
                To register with Vennflow please accept the <a href='https://www.vennflow.com/terms-and-conditions' rel="noreferrer" target='_blank' className={classes.TandC}>Terms and Conditions</a>
              </Typography>
            </Box>
            <LoadingButton
              id="registerClick"
              loading={loading}
              color="primary"
              variant="contained"
              type="submit"
              sx={{ marginTop: '1.5rem' }}
              disabled={!dirty || !isValid || !tandcAccepted}
            >
              Continue
            </LoadingButton>
          </Box>
        </Form>
        <Box className={classes.LocalFooter}>
          <hr />
          Already have an account? &nbsp;
          <Link onClick={() => navigate(`/${RoutePaths.Account}/${RoutePaths.SignIn}`)}>Log In</Link>
        </Box>
      </FormikProvider>
    </Fragment>
  );
};
