import { LoadingButton } from '@mui/lab';
import { Box, Typography, Button, TextField, Link, InputAdornment, styled } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { shopifySelectors } from 'store/entities/shopify';
import * as yup from 'yup';

import theme from '../../../../assets/theme/theme';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ShopLinkFormData } from '../../../../types/shopify/shopify';

interface Props {
  onSubmit: (values: ShopLinkFormData) => void;
}
//definition for store name entry
const StoreTextField = styled(TextField)(() => ({
  '.MuiOutlinedInput-root': {
    paddingLeft: 0,
    marginRight: 0,
  },
  '.MuiInputAdornment-root': {
    backgroundColor: '#f7b500',
    padding: '28px 14px',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  '.MuiInputAdornment-positionEnd': {
    backgroundColor: '#f7b500',
    marginRight: '-.789rem',
  },
}));

export const ShopLink: FC<Props> = ({ onSubmit }) => {
  const loading = useAppSelector(shopifySelectors.loading);
  const validationSchema = yup.object({
    shopName: yup
      .string()
      .min(3, 'Shop nane should have at least 3 characters length')
      .required('Shop name is required'),
  });

  const form = useFormik<ShopLinkFormData>({
    initialValues: {
      shopifyStoreLink: '',
      shopName: '',
      api: '',
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  const { values, touched, errors, isValid, dirty, handleChange, handleSubmit } = form;

  return (
    <Box component="div">
      <Typography component="div" variant="h3">
        Link Your Store
      </Typography>
      <Typography component="div">It's time to connect your store and bring your data into Vennflow.</Typography>
      <Typography component="div" style={{ fontSize: '14px', color: '#ff0000', marginTop: '1em' }}>
        * is a required field
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          required
          style={{ width: '400px' }}
          id="shopName"
          name="shopName"
          label="Shop Display Name (Nickname for the store)"
          value={values.shopName}
          onChange={handleChange}
          error={touched.shopName && Boolean(errors.shopName)}
          helperText={touched.shopName && errors.shopName}
          autoFocus
        />
        <br />

        <StoreTextField
          margin="normal"
          style={{ width: '400px' }}
          required
          label="Shopify Store name"
          id="shopifyStoreLink"
          name="shopifyStoreLink"
          value={values.shopifyStoreLink}
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">.myshopify.com</InputAdornment>,
          }}
          error={touched.shopifyStoreLink && Boolean(errors.shopifyStoreLink)}
          helperText={touched.shopifyStoreLink && errors.shopifyStoreLink}
        />
        <br />
        <br />

        <LoadingButton
          loading={loading}
          color="secondary"
          variant="outlined"
          type="submit"
          disabled={!dirty || !isValid}
        >
          Connect your Shopify shop
        </LoadingButton>
        <br />
        <br />

        <Typography component="div" color={theme.palette.text.secondary}>
          Once you click this button you will be referred to Shopify to complete the linking process.
        </Typography>
        <Link href="https://www.vennflow.com/faq" target="_blank">
          Need help understanding the Connectors and how to allow access? View out FAQ
        </Link>
      </Box>
    </Box>
  );
};
