import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const GoogleRedirect = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const init = async () => {
      localStorage.setItem('gacode', searchParams.get('code') || '');

      window.close();
    };

    init();
  }, []);

  return <>Redirecting...</>;
};
