import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { green, red } from '@mui/material/colors';
import { useField } from 'formik';
import { FC } from 'react';

interface Props {
  name: string;
}

export const StatusIcon: FC<Props> = ({ name }) => {
  const [{ value }, { error }] = useField(name);

  return <>{error || !value ? <CloseIcon sx={{ color: red[500] }} /> : <DoneIcon sx={{ color: green[500] }} />}</>;
};
