import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useToast } from 'hooks/use-toast';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';

import classes from './shopify-layout.module.css';
import logo from '../../assets/images/logo.svg';
import { ButtonState, SideButton } from '../../components/sideButton/SideButton';
import { accountSlice } from '../../store/entities/account';
import {
  getRegistrationStage,
  registrationStageSelectors,
  registrationStageSlice,
} from '../../store/entities/registration-stage';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Stage, UserFromToken } from '../../types/account/account';
import { AccountLayout } from '../account/AccountLayout';

export const ShopifyLayout = () => {
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();

  const stage = useAppSelector(registrationStageSelectors.stage);
  const loading = useAppSelector(registrationStageSelectors.loading);

  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
   
    try {
      if (accessToken) {
        const { UserId }: UserFromToken = jwtDecode(accessToken as string);

        dispatch(accountSlice.actions.setUserId(UserId));
        dispatch(getRegistrationStage(UserId as string, errorToast));
      } else {
        dispatch(registrationStageSlice.actions.registrationStageFailed());
      }
    } catch {
      errorToast();
    }
  }, []);

  const getButtonState = (): ButtonState[] => {
    switch (stage) {
      case Stage.ShopifyVerifyCode: {
        return [ButtonState.Completed, ButtonState.Active, ButtonState.Disabled];
      }
      case Stage.ShopifyBilling: {
        return [ButtonState.Completed, ButtonState.Completed, ButtonState.Active];
      }
      case Stage.BillingResult: {
        return [ButtonState.Completed, ButtonState.Completed, ButtonState.Completed];
      }
      default: {
        return [ButtonState.Active, ButtonState.Disabled, ButtonState.Disabled];
      }
    }
  };

  // stage !== Stage.Connectors for layout billingResult, after successfully billing
  return !loading && stage !== Stage.ShopifyBilling && stage !== Stage.BillingResult && stage !== Stage.Connectors ? (
    <AccountLayout />
  ) : (
    <Grid container component="div" sx={{ height: '100vh' }}>
      <Grid item xs={12} sm={10} md={10} component={Paper} elevation={6} square>
        <Link to={'/'}>
          <img src={logo} className={classes.Logo} alt="logo" />
        </Link>
        <Grid container alignItems={'center'}>
          <Grid item className={classes.AccountVariant}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={false}
        sm={2}
        md={2}
        sx={{
          backgroundImage: 'linear-gradient(282.4deg, #D40000 -111.32%, #F7B500 44.81%)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          alignItems: 'center',
          height: '100vh',
          display: 'flex',
          position: 'fixed',
          width: '100%',
          right: '0',
        }}
      >
        <Box sx={{ paddingTop: '284px' }}>
          <Box>
            <SideButton itemKey={1} title={'Shopify Registration'} state={getButtonState()[0]} />
            <SideButton itemKey={2} title={'Verify code'} state={getButtonState()[1]} />
            <SideButton itemKey={3} title={'Billing'} state={getButtonState()[2]} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
