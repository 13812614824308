import { CircularProgress, Grid, Link } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC, Fragment } from 'react';
import AuthCode from 'react-auth-code-input';
import { useNavigate } from 'react-router-dom';

import classes from './code-validation.module.css';
import { accountSelectors } from '../../store/entities/account/selectors';
import { useAppSelector } from '../../store/hooks';

export interface Props {
  loading: boolean;
  onSubmit: (code: string) => void;
}

export const CodeValidation: FC<Props> = ({ loading, onSubmit }) => {
  const navigate = useNavigate();

  const email = useAppSelector(accountSelectors.email);

  const handleOnChange = (code: string) => {
    if (code.length === 4) {
      onSubmit(code);
    }
  };

  return (
    <Fragment>
      <Typography component="div" variant="h3" className={classes.heading}>
        Check your email
      </Typography>
      <Typography component="div" style={{ marginTop: '1.2rem' }}>
        We sent your 4-digit code to <b>{email}</b>.<br />
        Please enter it below. <br />
        <br />
        <i style={{ fontSize: '14px' }}>(Remember to check your Junk mail folder, just in case.)</i>
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        {loading ? (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <AuthCode onChange={handleOnChange} length={4} inputClassName={classes.ValidationCodeInput} />
        )}
      </Box>
      <Box className={classes.LocalFooter}>
        <hr />
        <Link
          onClick={() => {
            navigate('/'); //TODO: resend e-mail call (email from state)
          }}
        >
          Click here
        </Link>
        to send a new code.
      </Box>
    </Fragment>
  );
};
