import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Fragment } from 'react';

import { FeatureData } from '../../../../../types/connectors/connectors';

export interface IStepSelectionProps {
  features: FeatureData[];
  onSelectFeature: any;
}

export const StepSelection = (props: IStepSelectionProps) => {
  const handleSelection = (feature: FeatureData) => {
    props.onSelectFeature(feature);
  };

  return (
    <Fragment>
      {props.features.map(feature => (
        <Box component="div" key={feature.id}>
          <Button
            onClick={() => handleSelection(feature)}
            color="primary"
            variant="outlined"
            size="small"
            sx={{ maxWidth: '100%', width: '100%', marginBottom: '10px', textTransform: 'none' }}
          >
            {feature.value} ({feature.id})
          </Button>
        </Box>
      ))}
    </Fragment>
  );
};
