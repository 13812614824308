import { MinimizeTwoTone } from '@mui/icons-material';
import { Card, Box, Zoom, CardContent, Grid,Typography, CardActions, Button } from '@mui/material';
import React from 'react';

export interface IAccountTypeCardProps {
    accountTypeImage: string,
    accountTitle:string,
    accountBlurb: string,
    onClick: () => void
}



export const AccountTypeCard = (props: IAccountTypeCardProps) => {

    return(
        <Grid item xs={4} lg={4}>
            <Grid item xs={12} lg={12}>
                <Card sx={{minHeight:'30rem'}}>
                    <Grid item textAlign='center' alignContent='center' xs={12} lg={12} sx={{marginTop:'2rem'}}>
                        <img src={props.accountTypeImage} 
                        width='180px'
                        />
                    </Grid>
                    <Grid item textAlign='center' alignContent='center' xs={12} lg={12}>
                        <Typography variant='h6'>
                            {props.accountTitle}
                        </Typography>
                    </Grid>
                    <br />
                    <Grid  item textAlign='center' alignContent='center' xs={12} lg={12} sx={{minHeight:'8rem', paddingLeft:'2em', paddingRight:'2em', textAlign:'justify'}}>
                        {props.accountBlurb}
                    </Grid>
                    <Grid item textAlign='center' xs={12} lg={12}>
                        <Button color='primary' variant='contained' onClick={() => props.onClick()} >I need this Account Type</Button>
                    </Grid>
                </Card>
            </Grid> 
        </Grid>
    );

};