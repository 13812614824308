import createApp from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';

import { getInstallLink } from '../../store/entities/shopify';
import { AppDispatch } from '../../store/store';

export const ShopifyInit = async (
  shopName: string,
  redirectUri: string,
  dispatch: AppDispatch,
  onFailure: () => void,
) => {
  try {
    const url = await dispatch(getInstallLink(shopName));
    // const permissionUrl = `https://${shopName}.myshopify.com/admin/oauth/authorize?client_id=${apiKey}&scope=read_products,read_content&redirect_uri=${redirectUri}`;
    const permissionUrl = url + `&redirect_uri=${redirectUri}`;
    // If the current window is the 'parent', change the URL by setting location.href

    if (window.top == window.self) {
      window.location.assign(permissionUrl);
      // If the current window is the 'child', change the parent's URL with Shopify App Bridge's Redirect action
    } else {
      const app = createApp({
        apiKey: process.env.REACT_APP_API_KEY as string,
        host: `${shopName}.myshopify.com`,
      });
      Redirect.create(app).dispatch(Redirect.Action.REMOTE, permissionUrl);
    }
  } catch {
    onFailure();
  }
};
