import { RegistrationStageInfo } from '../../types/account/account';
import { Response } from '../../types/api/api';
import { baseApi } from '../api';

const getRegistrationStage = (userId: string) =>
  baseApi.get<RegistrationStageInfo>(`Users/GetUserRegistrationStep/${userId}`);

const updateRegistrationStage = (registrationStage: RegistrationStageInfo) =>
  baseApi.put<Response<string>>('Users/SaveUsersRegistrationStep', { ...registrationStage });

export const registrationStageApi = {
  getRegistrationStage,
  updateRegistrationStage,
};
