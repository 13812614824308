import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CompanySlice } from './types';
import { Plan } from '../../../types/company/company';

const initialState: CompanySlice = {
  plans: [],
  plansLoading: true,
  companyRegisterLoading: false,
};

export const companySlice = createSlice({
  name: 'Company',
  initialState,
  reducers: {
    getPlansProcess: state => {
      state.plansLoading = true;
    },
    getPlansSuccess: (state, action: PayloadAction<Plan[]>) => {
      state.plansLoading = false;
      state.plans = action.payload;
    },
    getPlansFailed: state => {
      state.plansLoading = false;
    },

    companyRegisterProcess: state => {
      state.companyRegisterLoading = true;
    },
    companyRegisterSuccess: state => {
      state.companyRegisterLoading = false;
    },
    companyRegisterFailed: state => {
      state.companyRegisterLoading = false;
    },
  },
});
