import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { FC } from 'react';

import classes from './SideButton.module.css';
import CircleActiveIcon from '../../assets/icons/circle-active.svg';
import CircleDisabled from '../../assets/icons/circle-disabled.svg';
import CircleDone from '../../assets/icons/circle-done.svg';
import SelectedSideButton from '../../assets/icons/side-button-selected.svg';
import UnselectedSideButton from '../../assets/icons/side-button.svg';

export enum ButtonState {
  Completed = 'Completed',
  Active = 'Active',
  Disabled = 'Disabled',
}

export interface Props {
  title: string;
  state: ButtonState;
  itemKey: number;
  onClick?: () => void;
}

export const SideButton: FC<Props> = ({ state, title, itemKey, onClick }) => {
  const renderStateIcon = () => {
    switch (state) {
      case ButtonState.Completed: {
        return (
          <Avatar
            sx={{
              width: '54px',
              height: '54px',
              margin: '12px',
              backgroundImage: `url(${CircleDone})`,
              backgroundColor: 'transparent',
            }}
            alt="Completed"
          >
            &nbsp;
          </Avatar>
        );
      }
      case ButtonState.Active: {
        return (
          <Avatar
            sx={{
              width: '76px',
              height: '76px',
              margin: '1px',
              backgroundImage: `url(${CircleActiveIcon})`,
              backgroundColor: 'transparent',
            }}
            alt="Active"
          >
            {itemKey}
          </Avatar>
        );
      }
      case ButtonState.Disabled: {
        return (
          <Avatar
            sx={{
              width: '54px',
              height: '54px',
              margin: '12px',
              backgroundImage: `url(${CircleDisabled})`,
              backgroundColor: 'transparent',
            }}
            alt="disabled"
          >
            {itemKey}
          </Avatar>
        );
      }
    }
  };

  return (
    <Box
      className={state === ButtonState.Active ? `${classes.SideButton} ${classes.Selected}` : classes.SideButton}
      sx={{
        backgroundImage: state === ButtonState.Active ? `url(${SelectedSideButton})` : `url(${UnselectedSideButton})`,
      }}
      onClick={onClick}
    >
      {title}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>{renderStateIcon()}</Box>
    </Box>
  );
};
