export interface RegistrationRequest {
  externalId: string;
  parentExternalId: string;
  companyName: string;
  isShopifyReg?:boolean,
  industry: string;
  url: string;
  countryPrefix: string;
  contactNumber: string;
  contactName: string;
  addressLine1: string;
  county: string;
  postcode: string;
  country: string;
  city: string;
  isNonRetail: boolean;
}

export interface clientSystemAccessRequest {
  key: string;
  clientId: string;
  clientBrandId: string;
  clientBrandStoreId: string;
  systemId: string;
}

export interface GetAccessSystemIdResult {
  Id: string;
  brandId: string;
  brandName: string;
  clientBrandId: string;
  clientBrandStoreId: string;
  clientId: string;
  createdAt: string;
  expires: string;
  failedToRefreshToken: boolean;
  isArchived: boolean;
  key: string;
  refreshKey: string;
  relatedInformation: string;
  storeIdentifier: string;
  systemId: string;
  systemName: string;
  updatedAt: string;
}
export interface AllBrandInfo {
  Id: string;
  key: string;
  refreshKey: null;
  relatedInformation: null;
  expires: string;
  brandName: string;
  storeIdentifier: string;
  failedToRefreshToken: boolean;
  clientId: string;
  clientBrandStoreId: string;
  systemId: string;
  systemName: 'Facebook' | 'Google Analytics UA' | 'Shopify';
  clientBrandId: string;
  isArchived: boolean;
  createdAt: string;
  updatedAt: string;
}
export interface CreateClientMaperRequest {
  externalId: string;
  clientId: string;
  userId: string;
}

interface Country {
  code: string;
  name: string;
}

interface AddressDetails {
  country: Country;
  city: string;
  address: string;
  postcode: string;
}

interface Industry {
  id: string;
  name: string;
}
export interface CompanyRegistrationData {
  userId: string;
  name: string;
  industry: Industry;
  url: string;
  phoneCountryPrefix: string;
  phoneNumber: string;
  addressDetails: AddressDetails;
  selectedPlanId?: string;
}

export enum VennFlowPlan {
  VennFlow = 'VennFlow',
  VennFlowPlus = 'VennFlow+',
  VennFlowPlusPlus = 'VennFlow++',
}

export interface Plan {
  Id: string;
  title: VennFlowPlan;
  price: string;
  tools: string[];
  marketingAnalytics: string[];
  technical: string[];
  isDefaultSelected: boolean;
}

export interface ClientBrandStore {
  Id: string;
  clientId: string;
  clientBrandId: string;
  systemId: string;
  storeName: string;
  storeDescription: string;
  storeIdentifier: string;
  storeCurrencyUsed: string;
  storeChargeId: 0;
  isArchived: true;
  createdAt: string | Date;
  updatedAt: string | Date;
  command: '';
}

export interface Company {
  Id:string;
  externalId?: string;
  parentExternalId?: string;
  companyName?: string;
  contactName?: string;
  countryPrefix?: string;
  contactNumber?: string;
  addressLine1?: string;
  city?: string;
  county?: string;
  postcode?: string;
  country?: string;
  industry?: string;
  url?: string;
  allowMarketing: boolean;
  isArchived?: boolean;
  createdAt?: string;
  updatedAt: string | null;
}
