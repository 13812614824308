import { Typography } from '@mui/material';
import { useToast } from 'hooks/use-toast';
import { useState, useEffect, Fragment, useRef, FC } from 'react';
import { accountSelectors } from 'store/entities/account/selectors';

import { StepSelection } from './StepSelection';
import { CustomDialog } from '../../../../../components/dialog/CustomDialog';
import { getAdAccount, saveSelection as saveFeatureSelection } from '../../../../../store/entities/facebook';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { FeatureData } from '../../../../../types/connectors/connectors';
import { SelectedFeatures } from '../../../../../types/connectors/facebook-data';

export interface Props {
  open: boolean;
  systemAccessId: string;
  onFeaturesSelected: any;
  onClose: () => void;
}

enum FacebookSelectionStep {
  // BusinessName = 1,
  AdAccount = 2,
}

export const FacebookDataSelection: FC<Props> = ({ open, systemAccessId, onFeaturesSelected, onClose }) => {
  const dispatch = useAppDispatch();

  const { successToast, errorToast } = useToast();

  const [currentStep, setCurrentStep] = useState<FacebookSelectionStep>(FacebookSelectionStep.AdAccount);
  const [currentStepFeatures, setCurrentStepFeatures] = useState<FeatureData[]>();
  const selectedFeatures = useRef<SelectedFeatures>({});

  const clientId = useAppSelector(accountSelectors.clientId);
  const brandId = useAppSelector(accountSelectors.brandId);
  const brandStoreId = useAppSelector(accountSelectors.brandStoreId);
  const brandStore = localStorage.getItem('brandStoreId');


  useEffect(() => {
    if (!systemAccessId) return;

    const init = async () => {
      try {
        const adAccount = await dispatch(getAdAccount(systemAccessId));

        setCurrentStepFeatures(adAccount);
        setCurrentStep(FacebookSelectionStep.AdAccount);

        if( adAccount === null || adAccount?.length === 0)
        {
          errorToast('This account has no Facebook Ad Accounts, or you do not have access!');
        }

        if (adAccount?.length === 1) {
          const [autoSelectedFeature] = adAccount;

          saveSelection(autoSelectedFeature);
        }
      } catch {
        errorToast('An error occured getting a list of Facebook Ad Accounts!');
      }
    };

    init();
  }, [systemAccessId]);

  const saveSelection = async (feature: FeatureData) => {
    selectedFeatures.current = { ...selectedFeatures.current, adAccount: feature };

    try {

      //change brandStore back to brandStoreID once we are dispatching brandStoreid into the state machine
      await dispatch(saveFeatureSelection(clientId, brandId, (brandStoreId == null ? brandStore: brandStoreId), selectedFeatures.current));
      successToast('Facebook Data Connector was configured successfully');

      onFeaturesSelected();
    } catch {
      errorToast('An error occured saving your Facebook Ad Account selection!');
    }
  };

  const onSelectionUpdate = (feature: FeatureData) => {
    if (currentStep === FacebookSelectionStep.AdAccount) {
      return saveSelection(feature);
    }
  };

  return (
    <CustomDialog isModalOpen={open} ModalHandleClose={onClose} ModalTitle="Facebook Configuration - Step 1 of 1">
      <Typography gutterBottom>Please select your Facebook Ad Account you want Vennflow to connect to</Typography>
      {currentStepFeatures ? (
        <StepSelection features={currentStepFeatures} onSelectFeature={onSelectionUpdate} />
      ) : (
        <Fragment />
      )}
    </CustomDialog>
  );
};
