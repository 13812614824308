import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-green.svg';
import { settingsStyles as S } from '../../../connectors/styles';
import { FC } from 'react';

import { ReactComponent as ComingSoonIcon } from 'assets/icons/coming-soon.svg';

interface Props {
  name: string;
  compId: string;
  icon: string;
  onClick: () => void;
  onClickSettings?: () => void;
  connected: boolean;
  isConnectorComplete?: boolean;

  disabled?: boolean;
  variant?: 'default' | 'google' | 'facebook';
  comingSoon?: boolean;
  error?: boolean;
  custombuttonlabel?: string;
  customtooltip?: string;
}

export const DataConnectorsCardGA: FC<Props> = ({
  name,
  compId,
  icon,
  onClick,
  onClickSettings,
  connected,
  disabled,
  variant,
  comingSoon,
  isConnectorComplete,
  custombuttonlabel,
  customtooltip,
  error,
}) => {
  //const loading = useSelector(companySelectors.brandDataLoading);

  const renderConnectButton = () => {
    if(custombuttonlabel)
    {
      return (
        <Typography>
        {custombuttonlabel}
        </Typography>
        );
    }
    else
    {
      return (
        <Button   style={disabled ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}  size="small" id={compId} onClick={onClick}>
        Connect
        </Button>
      );
    }
  };

  return (
    <S.ConnectorsCard>
      {error && (
        <Box component="span" position="absolute" left="50%" marginTop="12px">
          <Tooltip title="More Configuration is needed for this connector, click the tile to configure">
            <ErrorIcon />
          </Tooltip>
        </Box>
      )}

      {connected && (
        <Box component="span" position="absolute" left="50%" marginTop="12px">
          {customtooltip ? (
            <Tooltip title={customtooltip}>
              <SuccessIcon>All good! Good to go.</SuccessIcon>
            </Tooltip>
          ) : (
            <Tooltip title="Configured successfully, you may proceed to next step.">
              <SuccessIcon>All good! Good to go.</SuccessIcon>
            </Tooltip>
          )}
        </Box>
      )}

      <CardContent>
        {/* <Box display="flex" justifyContent="center" mb={1}>
          {loading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <S.ConnectorsIcon src={icon} alt={name} />
          )}
        </Box> */}
        <Box display="flex" justifyContent="center" mb={1}>
          <S.ConnectorsIcon src={icon} alt={name} />
        </Box>
        <Typography align="center">{name}</Typography>
      </CardContent>

      {comingSoon && (
        <div
          style={{
            position: 'absolute',
            top: '-4px',
            right: '0',
            width: '80px',
            height: '80px',
          }}
        >
          <ComingSoonIcon />
        </div>
      )}
      {connected ? (
        <>
          {/* <IconButton
            color="primary"
            sx={{ position: 'absolute', top: '8px', right: '8px', width: '10px', height: '10px' }}
            onClick={onClickSettings}
          >
            <SettingsIcon />
          </IconButton> */}
          <Button fullWidth size="small" id={compId + 'Recon'} onClick={onClick}>
       Reconnect
          </Button>
        </>
      ) : (
        renderConnectButton()
      )}

   
    </S.ConnectorsCard>
  );
};
