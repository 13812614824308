import { stripeApi } from 'api/stripe';
import { AppDispatch } from '../../store';
import { CouponRequest, StripeCreateRequest, StripeStatusRequest } from 'types/stripe/stripe';

export const createInitialStripeSetup = (stripeUser: StripeCreateRequest) => async (dispatch: AppDispatch) => {
  const { data } = await stripeApi.createInitialStripeSetup(stripeUser);
  return data;
};

export const getStatus = (status: StripeStatusRequest) => async (dispatch: AppDispatch) => {
  const { data } = await stripeApi.getStatus(status);
  return data;
};

export const createCoupon = (coupon: CouponRequest) => async (dispatch: AppDispatch) => {
  const { data } = await stripeApi.applyCoupon(coupon);
  return data;
};

export const verifyCoupon = (coupon: string) => async (dispatch: AppDispatch) => {
  const { data } = await stripeApi.verifyCoupon(coupon);
  return data;
};
