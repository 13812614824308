import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import FinishAnimation from '../../assets/gifs/finish-animation.gif';

export const MsMarketingFinish: React.FC = () => {
  const handleLogin = () => {
    window.location.assign('Account/SignIn');
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ marginBottom: '3rem' }}
      alignItems="center"
      textAlign="center"
      zIndex={3}
      position="relative"
    >
      <Typography component="div" variant="h3" sx={{ marginBottom: '0.6rem' }}>
        Account Setup Completed!
      </Typography>
      <Typography component="div" color={theme => theme.palette.text.secondary} sx={{ marginBottom: '3rem' }}>
        Your login details have been sent to your email.
      </Typography>

      <Button onClick={handleLogin} color="primary" variant="contained" fullWidth style={{ marginBottom: '1.5rem' }}>
        Click here to Sign In to Vennflow
      </Button>

      <Typography component="div" color={theme => theme.palette.text.secondary}>
        If you do not see an email in you Inbox, please check your Junk folder.
      </Typography>
    </Box>
  );
};
