import { AddUserRequest, LoginRequest, LoginResult, ResetPasswordRequest, User } from '../../types/account/account';
import { Response } from '../../types/api/api';
import { baseApi } from '../api';

const login = (data: LoginRequest) => baseApi.post<Response<LoginResult>>('users/login', data);

const addUser = (data: AddUserRequest) => baseApi.post<Response<string>>('users/adduser', data);

const getUser = (userId: string) => baseApi.get<User>(`/users/${userId}`);

const verifyCode = (externalUserId: string, code: string) =>
  baseApi.post<Response<string>>('users/verifycode', { externalUserId, code });

const resetPassword = (params: ResetPasswordRequest) => baseApi.post('Users/CheckResetPassword', params);

const forgotPassword = (email: string) => baseApi.post('Users/PasswordReset', { email });

export const accountApi = {
  login,
  addUser,
  getUser,
  verifyCode,
  resetPassword,
  forgotPassword,
};
