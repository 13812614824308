import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { accountSlice } from './entities/account/slice';
import { companySlice } from './entities/company';
import { facebookSlice } from './entities/facebook';
import { googleAnalyticsSlice } from './entities/google-analytics';
import { registrationStageSlice } from './entities/registration-stage';
import { companyonboardingSlice } from './entities/companyAnalytics';
import { shopifySlice } from './entities/shopify';

export const store = configureStore({
  reducer: {
    account: accountSlice.reducer,
    company: companySlice.reducer,
    facebook: facebookSlice.reducer,
    googleAnalytics: googleAnalyticsSlice.reducer,
    registrationStage: registrationStageSlice.reducer,
    shopify: shopifySlice.reducer,
    companyAnalytic: companyonboardingSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
