import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GoogleAnalyticsSlice } from './types';

const initialState: GoogleAnalyticsSlice = {};

export const googleAnalyticsSlice = createSlice({
  name: 'googleAnalytics',
  initialState,
  reducers: {},
});
