import { Button, styled } from '@mui/material';

export const SecondaryButton = styled(Button)({
  fontWeight: '600',
  padding: '1rem',
  backgroundImage: 'linear-gradient(99.31deg, #44DEC5 -41.99%, #00A3FF 108.09%)',
  border: '4px solid rgba(255, 255, 255, 0.29)',
  borderRadius: '6px',
  ':disabled': {
    backgroundImage: 'none',
    backgroundColor: '#EBEEF2',
    color: '#0A2435',
    opacity: '1',
  },
  ':hover': {
    backgroundImage:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), linear-gradient(99.31deg, #44DEC5 -41.99%, #00A3FF 108.09%)',
  },
});
