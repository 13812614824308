import { RegistrationStageState } from './types';

const stage = (state: RegistrationStageState) => state.registrationStage.stage;

const loading = (state: RegistrationStageState) => state.registrationStage.loading;

export const registrationStageSelectors = {
  stage,
  loading,
};
