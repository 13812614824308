import { styled, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    maxWidth: 700,
    fontSize: theme.typography.pxToRem(16),
    arrow: {
      '&:before': {},
      color: theme.palette.common.white,
    },
  },
}));
