import React from 'react';

const LandingPage = () => {
  return (
    <div className="App">
      <h1 style={{ textAlign: 'center' }}>Landing Page</h1>
    </div>
  );
};

export default LandingPage;
