import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { FC, ReactNode } from 'react';

type Props = {
  name: string;
  children?: ReactNode;
} & TextFieldProps;

export const Field: FC<Props> = ({ children, name, ...restProps }) => {
  const [{ value, onChange }, { touched, error }] = useField({ name });

  return (
    <TextField
      id={name}
      name={name}
      value={value || ''}
      onChange={onChange}
      error={touched && !!error}
      helperText={touched && error ? error : ' '}
      margin="normal"
      variant="filled"
      {...restProps}
    >
      {children}
    </TextField>
  );
};
