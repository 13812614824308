import { Typography, Box } from '@mui/material';
import Zoom from '@mui/material/Zoom';

import { HtmlTooltip } from '../../components/htmlToolTip/HtmlToolTip';

export interface Props {
  features: string[];
  maxRows: number;
  text: string;
}

export const ExtraElementWithTooltip = ({ features, maxRows, text }: Props) => {
  const getExtras = () => {
    const extras = [];
    for (let i = maxRows - 1; i < features.length; i++) {
      extras.push(
        <Typography component="div" variant="body2" align="left" key={i}>
          ✔&nbsp;&nbsp;&nbsp;{features[i]}
        </Typography>,
      );
    }
    return extras;
  };

  return (
    <HtmlTooltip title={<Box>{getExtras()}</Box>} arrow followCursor TransitionComponent={Zoom}>
      <Typography component="div" variant="body2" align="left" key={maxRows - 1}>
        <b>{`+ ${features.length - maxRows} ${text}`}</b>
      </Typography>
    </HtmlTooltip>
  );
};
