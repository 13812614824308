import { LoadingButton } from '@mui/lab';
import { Typography, Box, Checkbox } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useToast } from 'hooks/use-toast';
import { FC, Fragment, useState } from 'react';

import { Field } from '../../../../../components/field/Field';
import { PasswordField } from '../../../../../components/password-field';
import { RegistrationFormData } from '../../../../../types/shopify/shopify';
import { shopifyRegistrationSchema } from '../helpers/shopify-registration-schema';

export interface Props {
  loading: boolean;
  classes: any;
  onSubmit: (values: RegistrationFormData) => void;
}

export const RegistrationForm: FC<Props> = ({ loading, classes, onSubmit }) => {
  const form = useFormik<RegistrationFormData>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      companyName: '',
      password: '',
    },
    validationSchema: shopifyRegistrationSchema,
    onSubmit: values => {
      if(tandcAccepted)
        onSubmit(values);
      else
        errorToast('Please accept the Terms and Conditions to register!');
    },
  });

  const { dirty, isValid } = form;
  const [tandcAccepted, setTandC] = useState(false);
  const { successToast, errorToast } = useToast();

  return (
    <Box>
      <Typography component="div" variant="h3" className={classes.heading}>
        Shopify Registration
      </Typography>
      <FormikProvider value={form}>
        <Form noValidate>
          <Field fullWidth required name="email" label="Email" autoFocus />
          <Field fullWidth required name="firstName" label="First Name" />
          <Field fullWidth required name="lastName" label="Last Name" />
          <Field fullWidth required name="companyName" label="Company Name" />
          <PasswordField name="password" label="password" showAdornment />
          <Typography component="div" className={classes.PasswordTextHolder}>
              The password requires a minimum of 8 characters, with at least 1 uppercase letter, 1 lowercase letter and
              one symbol
            </Typography>
            <Box className={classes.TandcHolder}>
              <Typography component="div" className={classes.tandcfont}>
                <Checkbox required size="medium" onClick={() => { setTandC(!tandcAccepted);}} />
                To register with Vennflow please accept the <a href='https://www.vennflow.com/terms-and-conditions' rel="noreferrer" target='_blank' className={classes.TandC}>Terms and Conditions</a>
              </Typography>
            </Box>
          <LoadingButton
            loading={loading}
            color="primary"
            variant="contained"
            type="submit"
            sx={{ marginTop: '1.5rem', width:'100%' }}
            disabled={!dirty || !isValid || !tandcAccepted}
          >
            Continue
          </LoadingButton>
        </Form>
      </FormikProvider>
    </Box>
  );
};
