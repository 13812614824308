import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CompanyAnalyticSlice } from './types';
import { AllBrandInfo } from 'types/company/company';

const initialState: CompanyAnalyticSlice = {
  
  brandLoading: true,
  allBrandData: [],
  
};

export const companyonboardingSlice = createSlice({
  name: 'companyAnalytics',
  initialState,
  reducers: {
   
    processGetBrandData: state => {
      state.brandLoading = true;
    },
    getBrandDataSuccess: (state, action: PayloadAction<AllBrandInfo[]>) => {
      state.allBrandData = action.payload;
      state.brandLoading = false;
    },
    getBrandDataFailure: state => {
      state.brandLoading = false;
    },

   
   
  },
});
