import { AccountState } from './types';

const email = (state: AccountState) => state.account.user.contactEmail;

const userId = (state: AccountState) => state.account.user.externalUserId;

const clientId = (state: AccountState) => state.account.user.clientId;

const brandId = (state: AccountState) => (state.account.user.brandList ? state.account.user.brandList[0].Id : null);

const brandStoreId = (state: AccountState) =>
  state.account.user.brandStoreList ? state.account.user.brandStoreList[0].Id : null;

const isNonRetail = (state: AccountState) => state.account.user.isNonRetail;
const isShopifyRegistration = (state: AccountState) => state.account.user.isShopifyReg;

const storeName = (state: AccountState) =>
  state.account.user.brandStoreList ? state.account.user.brandStoreList[0].Name : null;

const loading = (state: AccountState) => state.account.loading;

const user = (state: AccountState) => state.account.user;

export const accountSelectors = {
  email,
  loading,
  userId,
  clientId,
  brandId,
  brandStoreId,
  storeName,
  user,
  isNonRetail,
  isShopifyRegistration,
};
