import { LoadingButton } from '@mui/lab';
import { Typography, Box } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useToast } from 'hooks/use-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { resetPasswordSchema } from './helpers/reset-password-schema';
import classes from './ResetPasword.module.css';
import { ResetPasswordData } from './ResetPaswordData';
import theme from '../../../assets/theme/theme';
import { Field } from '../../../components/field/Field';
import { PasswordField } from '../../../components/password-field';
import { RoutePaths } from '../../../containers/RoutePaths';
import { resetPassword } from '../../../store/entities/account/actions';
import { accountSelectors } from '../../../store/entities/account/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const ResetPassword = () => {
  const dispatch = useAppDispatch();

  const { successToast, errorToast } = useToast();
  const navigate = useNavigate();
  const { code } = useParams<{ code: string }>();

  const loading = useAppSelector(accountSelectors.loading);

  const form = useFormik<ResetPasswordData>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values: ResetPasswordData) => {
      try {
        await dispatch(resetPassword({ ...values, code: code || '' }));

        successToast('Password was updated successfully');
        navigate(`/${RoutePaths.Account}/${RoutePaths.SignIn}`);
      } catch (error) {
        errorToast();
      }
    },
  });
  const { dirty, isValid, handleSubmit } = form;

  return (
    <Box className={classes.ContainerBox}>
      <Typography component="div" color={theme.palette.text.secondary} sx={{ marginBottom: '1.9rem' }}>
        Welcome to Vennflow
      </Typography>
      <Typography component="div" variant="h3">
        Change Password!
      </Typography>
      <Typography component="div">Please enter your email and your new password to update your password.</Typography>
      <FormikProvider value={form}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box className={classes.ContainerBox}>
            <Field required name="email" label="Email" type="email" />

            <PasswordField name="password" label="New Password" />

            <PasswordField name="confirmPassword" label="Confirm New Password" />
            
            <Typography component="div" sx={{ fontSize: '0.8rem' }}>
              The password requires a minimum of 8 characters, with at least 1 uppercase letter, 1 lowercase letter and
              one symbol
            </Typography>
            
            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              type="submit"
              sx={{ marginTop: '1.5rem' }}
              disabled={!dirty || !isValid}
            >
              Change Password
            </LoadingButton>
          </Box>
        </Box>
        <div />
      </FormikProvider>
    </Box>
  );
};
