import { createSlice } from '@reduxjs/toolkit';

import { ShopifySlice } from './types';

const initialState: ShopifySlice = {
  loading: false,
};

export const shopifySlice = createSlice({
  name: 'shopify',
  initialState,
  reducers: {
    process: state => {
      state.loading = true;
    },
    success: state => {
      state.loading = false;
    },
    failed: state => {
      state.loading = false;
    },
  },
});
