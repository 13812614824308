import { createSlice } from '@reduxjs/toolkit';

import { FacebookSlice } from './types';

const initialState: FacebookSlice = {};

export const facebookSlice = createSlice({
  name: 'facebook',
  initialState,
  reducers: {},
});
