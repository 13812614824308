export interface LoginRequest {
  username: string;
  password: string;
}

export interface AddUserRequest {
  contactEmail: string;
  firstName: string;
  lastName: string;
  password: string;
  isShopifyRegistration?: boolean;
}

export interface ResetPasswordRequest {
  email: string;
  code: string;
  password: string;
}

interface Brand {
  Id: string;
  Name: string;
}

interface BrandStore extends Brand {}
export interface User {
  brandList: Brand[];
  brandStoreList: BrandStore[];
  externalUserId: string;
  clientId: string;
  firstName: string;
  lastName: string;
  contactEmail: string;
  contactNumber: string;
  companyPosition: string;
  addressline1: string;
  addressline2: string;
  postcode: string;
  county: string;
  country: string;
  allowMarketing: boolean;
  isNonRetail: boolean;
  isShopifyReg: boolean;
}

export interface LoginResult {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  scope: string;
  error: string;
  error_description: string;
}

export interface SignUpFormData {
  firstName: string;
  lastName: string;
  contactEmail: string;
  companyName:string;
  password: string;
}

export enum Stage {
  AccountType = 'AccountLink',
  Company = 'Company',
  WebsiteDetail = 'WebsiteDetails',
  Plans = 'PlanSelection',
  Shop = 'ShopLink',
  ShopifyVerifyCode = 'ShopifyVerifyCode',
  ShopifyBilling = 'ShopifyBilling',
  BillingResult = 'BillingResult',
  Connectors = 'Connectors',
  Finished = 'Finished',
  ShopifyRegistration='ShopifyRegistration'
}

export interface RegistrationStageInfo {
  userId: string;
  stage: Stage;
}

export interface UserFromToken {
  UserId: string;
  Username: string;
  role: string;
}
