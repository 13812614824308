import { Response } from '../../types/api/api';
import { AllBrandInfo, ClientBrandStore, Company, CreateClientMaperRequest, GetAccessSystemIdResult, Plan, RegistrationRequest, clientSystemAccessRequest } from '../../types/company/company';
import { baseApi } from '../api';

const registration = (data: RegistrationRequest) => baseApi.post<Response<string>>('Companies', data);

const createClientMaper = (data: CreateClientMaperRequest) => baseApi.post('clientMapper/createClientMapper', data);

const getPlans = (isShopify: boolean,isMarketing:boolean ) => baseApi.get<Response<Plan[]>>(`Plans/GetAllPlans/${isShopify}?isMarketing=${isMarketing}`);


const getCompany = (clientName: string) =>
  baseApi.get<Response<Plan[]>>(`Companies/GetClientByClientName?clientName=${clientName}`);

  const updateCompany = (companyData: Company) => baseApi.post<Response<string>>('/Companies', companyData);

  const getCompanybyClient = (clientId: string) =>
  baseApi.get<Response<Company>>(`Companies/${clientId}`);


const setPlan = (userId: string, companyId: string, planId: string) =>
  baseApi.post('Companies/Plan', { externalId: userId, clientId: companyId, planId });

const addBrand = (clientId: string, brandName: string) =>
  baseApi.post<Response<string>>('brand', { clientId, brandName });

  const createBrandStore = (data: ClientBrandStore) => baseApi.post('Companies/ClientBrandStore', data);


  const getAllBrandInfo = (brandId: string) =>
  baseApi.get<Response<AllBrandInfo[]>>(`ClientSystemAccess/get-all-by-client-brand-id/${brandId}`);



  const getSystemAccessIdByClientId = (clientId: string) =>
  baseApi.get<Response<GetAccessSystemIdResult[]>>(`ClientSystemAccess/get-by-client-id/${clientId}`);


  const saveClientSystemAccess = (sytemaccessrequest: clientSystemAccessRequest) =>
  baseApi.post('ClientSystemAccess',  sytemaccessrequest );

  export const companyApi = {
  registration,
  createClientMaper,
  getPlans,
  setPlan,
  addBrand,
  getCompany,
  createBrandStore,
  getAllBrandInfo,
  getSystemAccessIdByClientId,
  saveClientSystemAccess,
  updateCompany,
  getCompanybyClient
};
