import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, TextField } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useCookies } from 'react-cookie';
import { useToast } from 'hooks/use-toast';
import { redirect } from 'react-router-dom';

interface FormData {
  name: string;
  email: string;
  subscriptionName: string;
  planName: string;
  subscriptionState: string;
  tenantId: string;
}

interface MSAuthTokenResponse {
  token_type: string;
  expires_in: string;
  ext_expires_in: string;
  expires_on: string;
  not_before: string;
  resource: string;
  access_token: string; // Adjust the type as needed based on actual data
}

interface SaasSubscription {
  id: string;
  subscriptionName: string;
  offerId: string;
  planId: string;
  quantity: number;
  subscription: {
    id: string;
    publisherId: string;
    offerId: string;
    name: string;
    saasSubscriptionStatus: string;
    beneficiary: {
      emailId: string;
      objectId: string;
      tenantId: string;
      puid: string;
    };
    purchaser: {
      emailId: string;
      objectId: string;
      tenantId: string;
      puid: string;
    };
    planId: string;
    term: {
      termUnit: string;
      startDate: string;
      endDate: string;
    };
    autoRenew: boolean;
    isTest: boolean;
    isFreeTrial: boolean;
    allowedCustomerOperations: string[];
    sandboxType: string;
    lastModified: string;
    quantity: number;
    sessionMode: string;
  };

  ms_auth_token: MSAuthTokenResponse;
  company: string;

  websiteUrl: string;
}

export const MarketPlaceLandingPage: React.FC = () => {
  const formDataString = localStorage.getItem('formData');
  const formData: FormData = formDataString ? JSON.parse(formDataString) : null;
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const [apiData, setApiData] = useState<SaasSubscription | null>(null);
  // State for input fields
  const [websiteUrl, setWebsiteUrl] = useState<string>('');
  const [storeName, setStoreName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [cookies, setCookie] = useCookies(['ms_auth_token']);
  const { errorToast, successToast } = useToast();

  useEffect(() => {
    const tenantId = process.env.REACT_APP_TENANT_ID;

    if (token) {
      setLoading(true);
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/MSmarketplace/GetSubscriptionPlan?tenantId=${tenantId}`,
            {
              headers: {
                'x-ms-marketplace-token': `${decodeURI(token)}`,
              },
            },
          );

          setApiData(response.data.result);
          setCookie('ms_auth_token', JSON.stringify(response.data.result.token.accessToken), {
            path: '/',
            maxAge: 3600 * 24 * 7,
          }); // Save for 7 days
          setLoading(false);
        } catch (error) {
          errorToast('Error fetching data');
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [token]);
  const handleWebsiteUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWebsiteUrl(event.target.value);
  };

  const handleStoreNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStoreName(event.target.value);
  };

  const createAccount = async () => {
    const msToken = cookies['ms_auth_token'];

    if (msToken && token) {
      setLoading(true);

      try {
        apiData.websiteUrl = websiteUrl;
        apiData.company = storeName;
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/MSmarketplace/ActivateSubscription?subscriptionID=${apiData.subscription.id}`,
          apiData,
          // {
          //   storeName: storeName,
          //   websiteUrl: websiteUrl,
          //   subscriptionID: apiData.subscription.id,
          // }
          {
            headers: {
              'Content-Type': 'application/json',
              'x-ms-marketplace-token': `${decodeURI(token)}`,
              authorization: `${msToken}`,
            },
          },
        );
        successToast('Account creation successful');
        setLoading(false);
        window.location.assign('/marketplacefinish');
      } catch (error) {
        errorToast('Error creating an account');
        setLoading(false);
      }
    }
  };

  if (formData) {
    return <Typography variant="h6">No data available</Typography>;
  }

  return (
    <Container maxWidth="sm" style={{ padding: '20px' }}>
      <Box>
        <Typography variant="h4" component="h1" align="center" style={{ marginTop: '-15%' }} gutterBottom>
          Thank you for your Subscription
        </Typography>
        <br />
        <Typography variant="body1" align="center" gutterBottom>
          Please verify the following information is correct before submitting your Subscription for activation by
          Vennflow
        </Typography>
        <br />
        <br />

        <Typography variant="body1">
          <strong>Email:</strong>
          {apiData?.subscription?.beneficiary?.emailId}
        </Typography>
        <Typography variant="body1">
          <strong>Subscription name:</strong> {apiData?.subscriptionName}
        </Typography>
        <Typography variant="body1">
          <strong>Plan name:</strong> {apiData?.planId}
        </Typography>
        <br />

        <TextField
          fullWidth
          label="Website URL"
          value={websiteUrl}
          onChange={handleWebsiteUrlChange}
          margin="normal"
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Store Display Name"
          value={storeName}
          onChange={handleStoreNameChange}
          margin="normal"
          variant="outlined"
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={createAccount}
          disabled={loading || !websiteUrl || !storeName}
        >
          {loading ? 'Creating Account...' : 'Create Account'}
        </Button>
      </Box>
    </Container>
  );
};
