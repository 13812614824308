import { Typography } from '@mui/material';
import { useToast } from 'hooks/use-toast';
import { useState, useEffect, Fragment, useRef, FC } from 'react';
import { accountSelectors } from 'store/entities/account/selectors';

import { StepSelection } from './StepSelection';
import { CustomDialog } from '../../../../../components/dialog/CustomDialog';
import {
  getAccounts,
  getProperties as getAccountProps,
  getViews as getAccountViews,
  saveSelection as saveFeatureSelection,
  saveSelectionGA4 as saveFeatureSelectionGA4,
  getPropertiesGA4 as getAccountPropsGA4,
  getViewsGA4 as getAccountViewsGA4,
} from '../../../../../store/entities/google-analytics';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { FeatureData, FeatureDataGA4 } from '../../../../../types/connectors/connectors';
import { SelectedFeatures, SelectedFeaturesGA4 } from '../../../../../types/connectors/google-analytics-data';
import { StepSelectionGA4 } from './StepSelectionGA4';
import { store } from 'store/store';
export interface Props {
  open: boolean;
  systemAccessId: string;
  onFeaturesSelected: any;
  onFeaturesSelectedGA4: any;
  onClose: () => void;
  isGA4?: boolean;
}

enum GoogleAnalyticsSelectionStep {
  Account = 1,
  Property = 2,
  View = 3,
}

export const GoogleAnalyticsDataSelection: FC<Props> = ({
  open,
  systemAccessId,
  onFeaturesSelected,
  onFeaturesSelectedGA4,
  onClose,
  isGA4 = false,
}) => {
  const dispatch = useAppDispatch();

  const { successToast, errorToast } = useToast();

  const [currentStep, setCurrentStep] = useState<GoogleAnalyticsSelectionStep>(GoogleAnalyticsSelectionStep.Account);
  const [currentStepFeatures, setCurrentStepFeatures] = useState<FeatureData[]>();
  const selectedFeatures = useRef<SelectedFeatures>({});
  const selectedFeaturesga4 = useRef<SelectedFeaturesGA4>({});
  const [currentStepFeaturesGA4, setCurrentStepFeaturesGA4] = useState<FeatureDataGA4[]>();
  const [g4State, setGA4State] = useState(isGA4);

  const clientId = useAppSelector(accountSelectors.clientId);
  const brandId = useAppSelector(accountSelectors.brandId);
  const brandStoreId = useAppSelector(accountSelectors.brandStoreId);

  useEffect(() => {
    if (!systemAccessId) return;
  
    const init = async () => {
      try {
        onloadCalls();
      } catch {
        errorToast();
      }
    };

    init();
  }, [systemAccessId]);

  const onloadCalls = async () => {
    if (isGA4) {
      const accounts = await dispatch(getAccountViewsGA4(systemAccessId));
      setCurrentStepFeaturesGA4(accounts);
      setCurrentStep(GoogleAnalyticsSelectionStep.Account);

      if (accounts.length === 1) {
        const [autoSelectedFeature] = accounts;

        getPropertiesGA4(autoSelectedFeature);
      }
    } else {
      const accounts = await dispatch(getAccounts(systemAccessId));

      setCurrentStepFeatures(accounts);
      setCurrentStep(GoogleAnalyticsSelectionStep.Account);

      if (accounts?.length === 1) {
        const [autoSelectedFeature] = accounts;

        getProperties(autoSelectedFeature);
      }
    }
  };

  const renderTitle = () => {
    switch (currentStep) {
      case GoogleAnalyticsSelectionStep.Account:
        return  'Google Analytics UA Configuration - Step 1 of 3'
          
      case GoogleAnalyticsSelectionStep.Property:
        return 'Google Analytics UA Configuration - Step 2 of 3'
         
      case GoogleAnalyticsSelectionStep.View:
        return  'Google Analytics UA Configuration - Step 3 of 3'
         
    }
  };

  const renderDescription = () => {
    switch (currentStep) {
      case GoogleAnalyticsSelectionStep.Account:
        return  (
          <Typography gutterBottom>
            Please select your Google Analytics UA Account you want Vennflow to connect to
          </Typography>
        );
      case GoogleAnalyticsSelectionStep.Property:
        return  (
          <Typography gutterBottom>
            Please select your Google Analytics UA Property you want Vennflow to connect to
          </Typography>
        ) ;
      case GoogleAnalyticsSelectionStep.View:
        return  (
          <Typography gutterBottom>
            Please select your Google Analytics UA View you want Vennflow to connect to
          </Typography>
        ) ;
    }
  };
  const saveSelectionGA4 = async (feature: FeatureDataGA4) => {
    selectedFeaturesga4.current = { ...selectedFeaturesga4.current, view: feature };
    try {

      const storeId = localStorage.getItem('brandStoreId');
      await dispatch(
        saveFeatureSelectionGA4(
          clientId,
          brandId,
          brandStoreId == null ? storeId : brandStoreId,
          selectedFeaturesga4.current,
        ),
      );

      successToast('Google Analytics 4 Data Connector was configure successfully');
      onFeaturesSelectedGA4();
    } catch {
      errorToast();
    }
  };

  const getPropertiesGA4 = async (feature: FeatureDataGA4) => {
    selectedFeaturesga4.current = { ...selectedFeaturesga4.current, account: feature };

    try {
      const properties = await dispatch(getAccountPropsGA4(systemAccessId, feature.name));
      setCurrentStepFeaturesGA4(properties);
      setCurrentStep(GoogleAnalyticsSelectionStep.Property);

      if (properties.length === 1) {
        const [autoSelectedFeature] = properties;

        getViews(autoSelectedFeature);
      }
    } catch {
      errorToast();
    }
  };

  const onSelectionUpdateGA4 = (feature: FeatureDataGA4) => {
    switch (currentStep) {
      case GoogleAnalyticsSelectionStep.Account:
        return getPropertiesGA4(feature);

      case GoogleAnalyticsSelectionStep.Property:
        //return getViewsGA4(feature);
        return saveSelectionGA4(feature);
      case GoogleAnalyticsSelectionStep.View:
        return saveSelectionGA4(feature);
    }
  };

  const getViewsGA4 = async (feature: FeatureDataGA4) => {
    selectedFeaturesga4.current = { ...selectedFeaturesga4.current, property: feature };

    try {
      const views = await dispatch(getAccountViewsGA4(systemAccessId));
      setCurrentStepFeaturesGA4(views);
      setCurrentStep(GoogleAnalyticsSelectionStep.View);

      if (views.length === 1) {
        const [autoSelectedFeature] = views;

        saveSelectionGA4(autoSelectedFeature);
      }
    } catch (error) {
      errorToast();
    }
  };
  const getProperties = async (feature: FeatureData) => {
    selectedFeatures.current = { ...selectedFeatures.current, account: feature };

    try {
      const properties = await dispatch(getAccountProps(systemAccessId, feature.id));

      setCurrentStepFeatures(properties);
      setCurrentStep(GoogleAnalyticsSelectionStep.Property);

      if (properties.length === 1) {
        const [autoSelectedFeature] = properties;

        getViews(autoSelectedFeature);
      }
    } catch {
      errorToast();
    }
  };

  const getViews = async (feature: FeatureData) => {
    selectedFeatures.current = { ...selectedFeatures.current, property: feature };

    try {
      const views = await dispatch(getAccountViews(systemAccessId, selectedFeatures.current.account!.id, feature.id));

      setCurrentStepFeatures(views);
      setCurrentStep(GoogleAnalyticsSelectionStep.View);

      if (views?.length === 1) {
        const [autoSelectedFeature] = views;

        saveSelection(autoSelectedFeature);
      }
    } catch {
      errorToast();
    }
  };

  const saveSelection = async (feature: FeatureData) => {
    selectedFeatures.current = { ...selectedFeatures.current, view: feature };

    try {
      const storeId = localStorage.getItem('brandStoreId');
      await dispatch(
        saveFeatureSelection(
          clientId,
          brandId,
          brandStoreId == null ? storeId : brandStoreId,
          selectedFeatures.current,
        ),
      );

      successToast('Google Analytics Data Connector was configure successfully');
      onFeaturesSelected();
    } catch {
      errorToast();
    }
  };

  const onSelectionUpdate = (feature: FeatureData) => {
    switch (currentStep) {
      case GoogleAnalyticsSelectionStep.Account:
        return getProperties(feature);

      case GoogleAnalyticsSelectionStep.Property:
        return getViews(feature);

      case GoogleAnalyticsSelectionStep.View:
        return saveSelection(feature);
    }
  };

  return  (
    <CustomDialog isModalOpen={open} ModalHandleClose={onClose} ModalTitle={renderTitle()}>
      {renderDescription()}
      {currentStepFeatures ? (
        <StepSelection features={currentStepFeatures} onSelectFeature={onSelectionUpdate} />
      ) : (
        <Fragment />
      )}
    </CustomDialog>
  ) ;
};
