import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { CircularProgress, SvgIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useToast } from 'hooks/use-toast';
import { Fragment, useEffect, useState } from 'react';

import { ExtraElementWithTooltip } from './ExtraElementWithTooltip';
import { ReactComponent as VennFlowPlusPlusPlanIcon } from '../../assets/icons/VennFlow++Plan.svg';
import { ReactComponent as VennFlowPlusPlanIcon } from '../../assets/icons/VennFlow+Plan.svg';
import { ReactComponent as VennFlowPlanIcon } from '../../assets/icons/VennFlowPlan.svg';
import theme from '../../assets/theme/theme';
import { SecondaryButton } from '../../components/button/SecondaryButton';
import { companySelectors, getPlans } from '../../store/entities/company';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Plan, VennFlowPlan } from '../../types/company/company';
import { accountSelectors } from 'store/entities/account/selectors';
import getStripe from 'lib/getStripe';
import Modal from '@mui/material/Modal';
import { Elements } from '@stripe/react-stripe-js';
import Checkout from 'components/stripe/Checkout';
import { useSelector } from 'react-redux';
import { getUser } from 'store/entities/account';

import './style.css'


export interface IPlanSelectionProps {
  shopify?: boolean;
  classes: any;
  onSubmit: (selectedPlan: Plan) => void;
}

export const PlanSelection = ({ shopify = false, classes, onSubmit }: IPlanSelectionProps) => {
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const stripePromise = getStripe();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const shopifyFaqSection: string =
    'https://help.shopify.com/en/manual/your-account/staff-accounts/staff-permissions/staff-permissions-descriptions#finance-permissions';

  const plans = useAppSelector(companySelectors.plans);
  const loading = useAppSelector(companySelectors.plansLoading);
  const clientId = useAppSelector(accountSelectors.clientId);
  const userId = useAppSelector(accountSelectors.userId);
  const isMarketingOnly = useAppSelector(accountSelectors.isNonRetail);
  const isShopifyRegistration = useAppSelector(accountSelectors.isShopifyRegistration);

  const [selectedPlanId, setSelectedPlanId] = useState('');

  const brandStoreId = useAppSelector(accountSelectors.brandStoreId);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await dispatch(getUser(userId));

        const plans = await dispatch(getPlans(user.isShopifyReg, user.isNonRetail));

        // Ensure that plans is not undefined before proceeding
        if (plans) {
          if (!selectedPlanId) {
            const defaultSelectedPlan = plans.find(plan => plan.isDefaultSelected);
            setSelectedPlanId(defaultSelectedPlan?.Id as string);
          }
        }
      } catch (error) {
        errorToast();
      }
    };

    // Call the async function only once

    if (plans.length == 0) {
      fetchData();
    }
  }, [userId]); // Include relevant dependencies
  // Include dependencies in the array as needed

  useEffect(() => {
    // Your code...
  }, [selectedPlanId]);
  const renderFeatures = (features: string[], maxRows: number, extraText: string): JSX.Element[] => {
    let toolsRendered: JSX.Element[] = [];
    if (features.length <= maxRows) {
      toolsRendered = features.map(line => (
        <Typography component="div" variant="body2" align="left" key={line}>
          ✔&nbsp;&nbsp;&nbsp;{line}
        </Typography>
      ));
      return toolsRendered;
    }

    for (let i = 0; i < maxRows - 1; i++) {
      toolsRendered.push(
        <Typography component="div" variant="body2" align="left" key={features[i]}>
          ✔&nbsp;&nbsp;&nbsp;{features[i]}
        </Typography>,
      );
    }
    toolsRendered.push(
      <ExtraElementWithTooltip key={features[maxRows - 1]} features={features} maxRows={maxRows} text={extraText} />,
    );
    return toolsRendered;
  };

  const getCardClassByType = (plan: VennFlowPlan) => {
    switch (plan) {
      case VennFlowPlan.VennFlow:
        return classes.PlanCardVennFlow;
      case VennFlowPlan.VennFlowPlus:
        return classes.PlanCardVennFlowPlus;
      case VennFlowPlan.VennFlowPlusPlus:
        return classes.PlanCardVennFlowPlusPlus;
    }
  };

  const getCardBySelected = (plan: any) => {
    return plan.planId === selectedPlanId
      ? `${classes.SelectedPlanCard} ${getCardClassByType(plan.title)}`
      : classes.UnselectedPlanCard;
  };

  const handleSubmit = () => {
    const selectedPlan =
      plans.length == 1
        ? plans.find(plan => plan.isDefaultSelected)
        : (plans.find(plan => plan.Id === selectedPlanId) as Plan);
    onSubmit(selectedPlan);
  };

  const getIconByType = (plan: VennFlowPlan) => {
    let planIcon;
    switch (plan) {
      case VennFlowPlan.VennFlow: {
        planIcon = <VennFlowPlanIcon />;
        break;
      }
      case VennFlowPlan.VennFlowPlus: {
        planIcon = <VennFlowPlusPlanIcon />;
        break;
      }
      case VennFlowPlan.VennFlowPlusPlus:
        planIcon = <VennFlowPlusPlusPlanIcon />;
    }
    return <SvgIcon>{planIcon}</SvgIcon>;
  };

  return (
    <Fragment>
      <Box sx={{ mb: 3 }}>
        <Typography component="div" variant="h3">
          Select a plan
        </Typography>
        <Typography component="div" gutterBottom variant="h5">
          Every plan comes with 30 days free! Cancel at any time.
        </Typography>
        {isShopifyRegistration && (
          <Typography>
            <br />
            You need the '<i>Manage App Billing</i>' permission on your Shopify user.{' '}
            <Link href={shopifyFaqSection} target="_blank">
              Find out more
            </Link>
            !
          </Typography>
        )}
      </Box>
      {plans && (
        <Box component="div">
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12} md={2}>
              <Box sx={{ height: '45rem' }}>
                <Box sx={{ height: '14rem' }}>
                  <Typography component="div" color={theme.palette.text.secondary} textAlign={'left'}>
                    Features
                  </Typography>
                </Box>
                <Box sx={{ height: '8rem' }}>
                  <Typography component="div" variant="body2" align="left" key={'Tools'}>
                    <HomeRepairServiceIcon /> Stores and Users
                  </Typography>
                </Box>
                <hr />
                <Box sx={{ height: '14rem' }}>
                  <Typography component="div" variant="body2" align="left" key={'Tools'}>
                    <AutoGraphIcon /> Features
                  </Typography>
                </Box>
                <hr />
                <Box sx={{ height: '8rem' }}>
                  <Typography component="div" variant="body2" align="left" key={'Tools'}>
                    <MiscellaneousServicesIcon /> Extras
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {loading ? (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              </Grid>
            ) : (
              plans.map(plan => (
                <Grid item key={plan.title} xs={12} md={2} sx={{ minWidth: '250px;' }}>
                  <Card className={`${classes.PlanCardBase} ${getCardBySelected(plan)}`}>
                    <CardContent
                      sx={{ height: '45rem' }}
                      className={`${classes.PlanCardHeader} ${getCardClassByType(plan.title)}`}
                    >
                      <Box
                        sx={{
                          height: '12em',
                          textAlign: 'center',
                        }}
                      >
                        <Typography component="div" variant="h6" sx={{ my: '0.5rem' }}>
                          {plan.title}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'baseline',
                            height: '3.5rem',
                          }}
                        >
                          {plan.price === "Let's talk" ? (
                            <Typography component="h4" variant="h4" color="text.primary">
                              {plan.price}
                            </Typography>
                          ) : (
                            <Fragment>
                              <Typography component="h4" variant="h4" color="text.primary">
                                ${plan.price}
                              </Typography>
                              <Typography variant="h6" color="text.secondary">
                                /month
                              </Typography>
                            </Fragment>
                          )}
                        </Box>
                        <SecondaryButton
                          onClick={() => setSelectedPlanId(plan.Id)}
                          disabled={plan.Id === selectedPlanId || plans.length == 1}
                        >
                          {selectedPlanId === plan.Id || plans.length == 1 ? '✔  Selected' : 'Select plan'}
                        </SecondaryButton>
                      </Box>
                      <Box sx={{ height: '8rem' }}>{renderFeatures(plan.tools, 12, 'Extra tools')}</Box>
                      <hr />
                      <Box sx={{ height: '14rem' }}>
                        {renderFeatures(plan.marketingAnalytics, 12, 'Analytics assets')}
                      </Box>
                      <hr />
                      <Box sx={{ height: '8rem' }}>{renderFeatures(plan.technical, 12, 'Technical assets')}</Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      )}
      <Box display="flex" justifyContent="end" paddingTop="1.5rem">
        <div>
          {/* Your other components */}
          {/* <StripeCheckoutButton price={19.99} /> */}
          {/* <button onClick={handleCheckout}>Checkout</button> */}
        </div>
        <div className={classes.LocalFooterButtons}>
          {isShopifyRegistration ? (
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Next
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={handleOpen}
              disabled={plans.length > 1 && !selectedPlanId}
            >
              Checkout
            </Button>
          )}
        </div>
      </Box>

      <Modal open={open} onClose={handleClose} aria-labelledby="Checkout" aria-describedby="modal-modal-description">
        <Box className='stripeHolder'>
          <Elements stripe={stripePromise}>
            <Checkout
              selectedPlanId={plans.length == 1 ? plans.find(plan => plan.isDefaultSelected).Id : selectedPlanId}
              clientId={clientId}
              plan={
                plans.length == 1
                  ? plans.find(plan => plan.isDefaultSelected)
                  : plans.find(plan => plan.Id == selectedPlanId)
              }
            />
          </Elements>
        </Box>
      </Modal>
    </Fragment>
  );
};
